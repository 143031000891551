import df, { formatDatetime, formatDuration } from "@/common/date.filter";
import isNumber from "@/common/util/isNumber";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Timekeeping Summary report contains Clock-in and Clock-out metrics grouped by Clock-in date.",
  Arabic:
    "يحتوي تقرير 'ملخص الجدول الزمني' على مقاييس تسجيل الحضور و تسجيل الخروج مجمعة حسب تاريخ تسجيل الحضور",
  default:
    "The Timekeeping Summary report contains Clock-in and Clock-out metrics grouped by Clock-in date.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/log/clockedin/summary`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Timekeeping Summary Report",
    headers: [
      "param",
      "Date",
      "ID",
      "Name",
      "Resource",
      "Clocked-in",
      "First In",
      "Last Out",
      "Duration",
    ],
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    actions: [
      {
        name: "Detail",
        icon: "view_list",
        handler: function (event, router) {
          router.push({
            name: "Timekeeping Detail",
            query: {
              id: event.param,
            },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => ({
          // use `param` to avoid dup key error; `id` is reserved for key
          param: x.asset_id,
          Date: df(x.date),
          ID: x.employee_id,
          Name: x.name,
          Resource: x.resource_name,
          "Clock-ins": x.count_clockin,
          "First In": formatDatetime(x.first_clockin),
          "Last Out": formatDatetime(x.last_clockout),
          Duration: formatDuration(x.minutes_clocked_in + x.active_clockin_minutes || 0),
          "Clocked-in": isNumber(x.active_clockin_minutes) ? "Yes" : "No",
          resourcetype_name: x.resourcetype_name,
        }));
    },
  },
};
