export default (customHeaderMaxCount, shouldResetDisabledFields = true) => {
  const validateString = (val) =>
    typeof val === "string" && val.length > 0 && val.trim().length !== 0;

  const initialCustomHeader = {
    isEnabled: false,
    key: null,
    value: null,
  };
  const customHeaderKeys = Array.from(Array(customHeaderMaxCount)).map(
    (_x, i) => `customHeader${i + 1}`
  );
  const customHeaders = Object.fromEntries(
    customHeaderKeys.map((name) => [name, { ...initialCustomHeader }])
  );
  const customHeaderFields = customHeaderKeys.flatMap((customHeaderKey, index) => {
    const previousCustomHeaderKeys = customHeaderKeys.slice(0, index);
    const currentCustomHeaderKeys = customHeaderKeys.slice(0, index + 1);
    const nextCustomHeaderKeys = customHeaderKeys.slice(index + 1);

    const getPreviousCustomHeadersAreEnabled = (model) =>
      previousCustomHeaderKeys.every((key) => model.customHeaders[key].isEnabled);

    const getCurrentCustomHeadersAreEnabled = (model) =>
      currentCustomHeaderKeys.every((key) => model.customHeaders[key].isEnabled);

    return [
      {
        type: "md-switch",
        label: `Custom Header ${index + 1}`,
        model: `customHeaders.${customHeaderKey}.isEnabled`,
        validator: [
          (_value, _field, model) => {
            const currentCustomHeadersAreEnabled = getCurrentCustomHeadersAreEnabled(model);

            if (!currentCustomHeadersAreEnabled) {
              // Current or previous custom header is not enabled; skip validation
              return [];
            }

            const isValidKey = validateString(model.customHeaders[customHeaderKey].key);

            return isValidKey ? [] : ["Invalid key"];
          },
          (_value, _field, model) => {
            const currentCustomHeadersAreEnabled = getCurrentCustomHeadersAreEnabled(model);

            if (!currentCustomHeadersAreEnabled) {
              // Current or previous custom header is not enabled; skip validation
              return [];
            }

            const isValidValue = validateString(model.customHeaders[customHeaderKey].value);

            return isValidValue ? [] : ["Invalid value"];
          },
        ],
        visible: (model) => model && (index === 0 || getPreviousCustomHeadersAreEnabled(model)),
        styleClasses: [
          "field-md-switch--single-row",
          "col-md-100",
          "md-layout-item",
          "md-size-100",
        ],
        onChanged: (model, newVal, _oldVal, _field) => {
          const isEnabled = newVal === true;

          if (isEnabled) {
            return;
          }

          [customHeaderKey, ...nextCustomHeaderKeys].forEach((key) => {
            if (!shouldResetDisabledFields) {
              model.customHeaders[key].isEnabled = false;

              return;
            }

            model.customHeaders[key] = { ...initialCustomHeader };
          });
        },
      },
      {
        type: "input",
        inputType: "text",
        label: "Key",
        model: `customHeaders.${customHeaderKey}.key`,
        required: true,
        validator: [
          (value, _field, model) => {
            const currentCustomHeadersAreEnabled = getCurrentCustomHeadersAreEnabled(model);

            if (!currentCustomHeadersAreEnabled) {
              // Current or previous custom header is not enabled; skip validation
              return [];
            }

            const isValidValue = validateString(value);

            return isValidValue ? [] : ["This field is required!"];
          },
        ],
        visible: (model) => model && getCurrentCustomHeadersAreEnabled(model),
        styleClasses: ["col-md-50", "md-layout-item", "md-size-50"],
      },
      {
        type: "input",
        inputType: "text",
        label: "Value",
        model: `customHeaders.${customHeaderKey}.value`,
        required: true,
        validator: [
          (value, _field, model) => {
            const currentCustomHeadersAreEnabled = getCurrentCustomHeadersAreEnabled(model);

            if (!currentCustomHeadersAreEnabled) {
              // Current or previous custom header is not enabled; skip validation
              return [];
            }

            const isValidValue = validateString(value);

            return isValidValue ? [] : ["This field is required!"];
          },
        ],
        visible: (model) => model && getCurrentCustomHeadersAreEnabled(model),
        styleClasses: ["col-md-50", "md-layout-item", "md-size-50"],
      },
    ];
  });

  return {
    customHeaders,
    customHeaderFields,
    customHeaderKeys,
    initialCustomHeader,
  };
};
