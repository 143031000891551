export const startDateValidation = (value, field, model) => {
  if (value && model.end && new Date(value).valueOf() > model.end) {
    return ["Start Date must be before End Date."];
  } else {
    return [];
  }
};

export const endDateValidation = (value, field, model) => {
  if (value && model.start && new Date(value).valueOf() < model.start) {
    return ["End Date must be after Start Date."];
  } else {
    return [];
  }
};

const parseTime = (value) => {
  return parseInt(value.split(":").join(""), 10);
};

export const startTimeValidation = (value, field, model) => {
  if (value && model.end && parseTime(value) > parseTime(model.end)) {
    return ["Start Time must be before End Time"];
  } else {
    return [];
  }
};

export const endTimeValidation = (value, field, model) => {
  if (value && model.start && parseTime(value) < parseTime(model.start)) {
    return ["End Time must be after Start Time"];
  } else {
    return [];
  }
};
