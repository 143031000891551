const keyPrefix = "@@OG_CORE@@";
const createKey = (e) => `${keyPrefix}::${e.client_id}::${e.audience}::${e.scope}`;

/**
 * Wraps the specified cache entry and returns the payload
 * @param entry The cache entry to wrap
 */

const wrapCacheEntry = (entry) => {
  const expiresInTime = Math.floor(Date.now() / 1000) + entry.expires_in;
  const expirySeconds = Math.min(expiresInTime, entry.decodedToken.exp) - 60; // take off a small leeway

  return {
    body: entry,
    expiresAt: expirySeconds,
  };
};

export class InMemoryCache {
  constructor() {
    this.enclosedCache = (function () {
      let cache = {
        body: {},
        expiresAt: 0,
      };

      return {
        save(entry) {
          const key = createKey(entry);
          const payload = wrapCacheEntry(entry);

          cache[key] = payload;
        },
        get(key) {
          const cacheKey = createKey(key);
          const wrappedEntry = cache[cacheKey];
          const nowSeconds = Math.floor(Date.now() / 1000);

          if (!wrappedEntry) {
            return;
          }

          if (wrappedEntry.expiresAt < nowSeconds) {
            if (wrappedEntry.body.refresh_token) {
              wrappedEntry.body = {
                refresh_token: wrappedEntry.body.refresh_token,
              };

              return wrappedEntry.body;
            }

            delete cache[cacheKey];

            return;
          }

          return wrappedEntry.body;
        },
        clear() {
          cache = {
            body: {},
            expiresAt: 0,
          };
        },
      };
    })();
  }
}
