import * as projectProps from "@/pages/Project/routerProps";

export const projectRoutes = [
  {
    path: "/project",
    component: () => import("@/pages/Project/Layout/ProjectLayout.vue"),
    children: [
      {
        path: "/",
        name: "Project Home",
        components: {
          default: () => import("@/pages/Project/Project.vue"),
        },
        meta: {
          title: "Project Map",
        },
      },
      {
        path: "assets",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Project Assets",
            component: () => import("@/pages/Project/Pages/Details/AssetList.vue"),
            meta: {
              title: "Project Resources",
            },
          },
          {
            path: ":id",
            name: "ProjectAssetDetail",
            components: {
              default: () => import("@/pages/Project/Pages/Details/AssetDetail.vue"),
            },
            meta: {
              title: "Project Resource Details",
            },
          },
          {
            path: ":id/contacts",
            name: "ProjectAssetContacts",
            component: () => import("@/pages/Project/Pages/Details/AssetContacts.vue"),
            meta: {
              title: "Assign Team",
            },
          },
        ],
      },
      {
        path: "locations",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Project Locations",
            component: () => import("@/pages/Project/Pages/Locations/LocationList.vue"),
            meta: {
              title: "Project Locations",
            },
          },
          {
            path: ":id",
            name: "ProjectLocationDetail",
            components: {
              default: () => import("@/pages/Project/Pages/Locations/LocationDetail.vue"),
            },
            meta: {
              title: "Project Location Details",
            },
          },
        ],
      },
      {
        path: "points",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Project Points",
            component: () => import("@/pages/Project/Pages/Locations/PointList.vue"),
            meta: {
              title: "Project Points",
            },
          },
          {
            path: ":id",
            name: "ProjectPointDetail",
            components: {
              default: () => import("@/pages/Project/Pages/Locations/PointDetail.vue"),
            },
            meta: {
              title: "Project Point Details",
            },
          },
        ],
      },
      {
        path: "building/:building_id",
        name: "ProjectMapBuilding",
        components: {
          default: () => import("@/components/_ottogee/Maps/ProjectMapBuilding.vue"),
        },
        meta: {
          title: "Interior Map",
        },
      },
      {
        path: "building/:building_id/asset/:asset_id",
        name: "AssetMapBuilding",
        components: {
          default: () => import("@/components/_ottogee/Maps/AssetMapBuilding.vue"),
        },
        meta: {
          title: "Interior Map",
        },
      },
      {
        path: "charts/losttime",
        name: "Lost Time",
        components: {
          default: () => import("@/pages/Project/Pages/Reports/Charts/LostTime.vue"),
        },
        meta: {
          title: "Lost Time Report",
        },
      },
      {
        path: "charts/attendance",
        name: "Attendance Charts",
        components: {
          default: () => import("@/pages/Project/Pages/Reports/Charts/Attendance.vue"),
        },
      },
      {
        path: "assetmap/:id/",
        name: "Asset Map",
        components: {
          default: () => import("@/pages/Project/Pages/Maps/AssetMap.vue"),
        },
        meta: {
          title: "Resource Map",
        },
      },
      {
        path: "incidents/map/",
        name: "Incident Map",
        components: {
          default: () => import("@/pages/Project/Pages/Incidents/Map.vue"),
        },
      },
      {
        path: "setup",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "map",
            component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "",
                name: "PlanningMap",
                components: {
                  default: () => import("@/components/_ottogee/Maps/PlanningMap.vue"),
                },
                meta: {
                  filterHeading: "Setup",
                  title: "Sitemap",
                },
              },
              {
                path: "/building/:building_id",
                name: "BuildingMap",
                components: {
                  default: () => import("@/pages/Project/Pages/Maps/BuildingMap.vue"),
                },
                meta: {
                  filterHeading: "Setup",
                  title: "Indoor Mapping",
                },
              },
            ],
          },
          {
            path: "safety",
            component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "/",
                name: "Project Safety",
                component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
                props: function () {
                  return projectProps.safetyRuleList;
                },
                meta: {
                  filterHeading: "Setup",
                  title: "Safety Rules",
                },
              },
              {
                path: ":id",
                component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
                children: [
                  {
                    path: "/",
                    name: "Project Safety Detail",
                    component: () => import("@/pages/Project/Pages/Safety/Detail.vue"),
                    meta: {
                      title: "Safety Rule Details",
                    },
                  },
                  {
                    path: "edit",
                    name: "Project Safety Edit",
                    component: () => import("@/pages/Project/Pages/Safety/Edit.vue"),
                    meta: {
                      title: "Edit Safety Rule",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "shift",
            name: "Project Shift",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return projectProps.shiftList;
            },
            meta: {
              filterHeading: "Setup",
              title: "Shifts",
            },
          },
        ],
      },
      {
        path: "resourceplanning",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Resource Planning",
            components: {
              default: () => import("@/pages/Project/Pages/Timesheet/Timesheet.vue"),
            },
            meta: {
              filterHeading: "Planning",
              title: "Plan Resources",
            },
          },
          {
            path: "bulk",
            component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "assignteam",
                name: "Bulk Assign Team",
                component: () => import("@/pages/Project/Pages/Timesheet/Bulk/TeamAssign/Page.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "activity",
        name: "Project Activity",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return projectProps.activityList;
        },
        meta: {
          filterHeading: "Planning",
          title: "Activities",
        },
      },
      {
        path: "activity/:id/detail",
        name: "ActivityDetail",
        components: {
          default: () => import("@/pages/Project/Pages/Activity/Detail.vue"),
        },
        meta: {
          filterHeading: "Planning",
          title: "Activity Details",
        },
      },
      {
        path: "activity/:id/edit-locations",
        name: "Activity Edit Locations",
        component: () => import("@/pages/Project/Pages/Activity/EditLocations.vue"),
        meta: {
          title: "Edit Activity Locations",
        },
      },
      {
        path: "activity/:id/update",
        name: "ActivityUpdate",
        components: {
          default: () => import("@/pages/Project/Pages/Activity/Update.vue"),
        },
        meta: {
          filterHeading: "Planning",
          title: "Update Quantities",
        },
      },
      {
        path: "productivity",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "",
            name: "Productivity Overview",
            components: {
              default: () => import("@/pages/Project/Pages/Productivity/Overview.vue"),
            },
            meta: {
              title: "Productivity",
            },
          },
          {
            path: "activity/:activity_id",
            name: "ProductivityActivity",
            components: {
              default: () => import("@/pages/Project/Pages/Productivity/Activity.vue"),
            },
            meta: {
              filterHeading: "Cumulative to Date",
              title: "Activity Productivity",
            },
          },
        ],
      },
      {
        path: "costs",
        name: "CostsOverview",
        components: {
          default: () => import("@/pages/Project/Pages/Costs/Overview.vue"),
        },
        meta: {
          title: "Cost",
        },
      },
      {
        path: "costs/activity/:activity_id",
        name: "CostsActivity",
        components: {
          default: () => import("@/pages/Project/Pages/Costs/Activity.vue"),
        },
        meta: {
          filterHeading: "Cumulative to Date",
          title: "Activity Costs",
        },
      },
      {
        path: "safety/communicate",
        name: "Communication",
        component: () => import("@/pages/Project/Pages/Reports/Safety/Communicate.vue"),
      },
      {
        path: "safety/devicealert",
        name: "SendDeviceAlert",
        component: () => import("@/pages/Project/Pages/Reports/Safety/SendDeviceAlert.vue"),
      },
      {
        path: "safety/compliance",
        name: "Contact Metrics",
        component: () => import("@/pages/Project/Pages/Reports/Safety/Compliance.vue"),
      },

      {
        path: "safety/contacthistory",
        name: "SafetyContacts",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.contactHistory;
        },
        meta: {
          title: "Contact Records",
        },
      },
      {
        path: "safety/highriskcontacts",
        name: "SafetyHighRiskContacts",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.highRiskContacts;
        },
        meta: {
          title: "High Risk Contacts",
        },
      },
      {
        path: "safety/unauthorizedzone",
        name: "Unauthorized Zone",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.unauthorizedZone;
        },
        meta: {
          title: "Rule Violations",
        },
      },
      {
        path: "alignment",
        name: "Alignment",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.timesheet;
        },
        meta: {
          title: "Alignment",
        },
      },
      {
        path: "timeonsite",
        name: "Attendance Table",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.timeOnSite;
        },
      },
      {
        path: "timeoffsite",
        name: "Time Off Site",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.timeOffSite;
        },
      },
      {
        path: "overtime",
        name: "Overtime",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.overtime;
        },
        meta: {
          title: "Overtime",
        },
      },
      {
        path: "inzone",
        name: "Project in Zone",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.hoursEachZone;
        },
        meta: {
          title: "Time in Zones",
        },
      },
      {
        path: "zonetransitions",
        name: "Zone Transitions",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.zoneTransition;
        },
        meta: {
          title: "Zone Transitions",
        },
      },
      {
        path: "zonearrival",
        name: "Arrival to Zone",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.zoneArrival;
        },
        meta: {
          title: "Arrival to Zone",
        },
      },
      {
        path: "inarea",
        name: "Project in Area",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.hoursEachArea;
        },
        meta: {
          title: "Time in Areas",
        },
      },
      {
        path: "workersinzonebyhour",
        name: "Workers in Zone by Hour",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.workersInZoneByHour;
        },
        meta: {
          title: "Workers in Zone by Hour",
        },
      },
      {
        path: "utilization",
        name: "Equipment Utilization",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          // Updated for alec
          return projectProps.telemetry;
        },
      },
      {
        path: "toolutilization",
        name: "Tool Utilization",
        component: () => import("@/pages/Project/Components/Reports/Report.vue"),
        props: function () {
          return projectProps.toolUtilization;
        },
      },
      {
        path: "timekeeping",
        component: () => import("@/pages/Project/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Timekeeping Summary",
            component: () => import("@/pages/Project/Components/Reports/Report.vue"),
            props: function () {
              return projectProps.timekeepingSummary;
            },
            meta: {
              title: "Timekeeping Summary",
            },
          },
          {
            path: "detail",
            name: "Timekeeping Detail",
            component: () => import("@/pages/Project/Components/Reports/Report.vue"),
            props: function () {
              return projectProps.timekeepingDetail;
            },
            meta: {
              title: "Timekeeping",
            },
          },
        ],
      },
      {
        path: "more-reports",
        name: "More Reports",
        component: () => import("@/pages/Project/Pages/Reports/Reports.vue"),
        meta: {
          title: "More Reports",
        },
      },
    ],
  },
];
