/**
 * Array of country objects for the vue-tel-flag flag dropdown
 *
 * Criteria for the vue-tel-input plugin to support a given country/territory:
 * - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 * - It has its own country calling code (it is not a sub-region of another country): https://en.wikipedia.org/wiki/List_of_country_calling_codes
 * - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
 * - It is supported by libphonenumber (it must be listed on this page): https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml
 */

const countries = [
  {
    name: "Afghanistan (‫افغانستان‬‎)",
    iso2: "AF",
    dialCode: "93",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Albania (Shqipëri)",
    iso2: "AL",
    dialCode: "355",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Algeria (‫الجزائر‬‎)",
    iso2: "DZ",
    dialCode: "213",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "American Samoa",
    iso2: "AS",
    dialCode: "1684",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Andorra",
    iso2: "AD",
    dialCode: "376",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Angola",
    iso2: "AO",
    dialCode: "244",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Anguilla",
    iso2: "AI",
    dialCode: "1264",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    dialCode: "1268",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Argentina",
    iso2: "AR",
    dialCode: "54",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Armenia (Հայաստան)",
    iso2: "AM",
    dialCode: "374",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Aruba",
    iso2: "AW",
    dialCode: "297",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Australia",
    iso2: "AU",
    dialCode: "61",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Austria (Österreich)",
    iso2: "AT",
    dialCode: "43",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    iso2: "AZ",
    dialCode: "994",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bahamas",
    iso2: "BS",
    dialCode: "1242",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bahrain (‫البحرين‬‎)",
    iso2: "BH",
    dialCode: "973",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    iso2: "BD",
    dialCode: "880",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Barbados",
    iso2: "BB",
    dialCode: "1246",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Belarus (Беларусь)",
    iso2: "BY",
    dialCode: "375",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Belgium (België)",
    iso2: "BE",
    dialCode: "32",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Belize",
    iso2: "BZ",
    dialCode: "501",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Benin (Bénin)",
    iso2: "BJ",
    dialCode: "229",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bermuda",
    iso2: "BM",
    dialCode: "1441",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bhutan (འབྲུག)",
    iso2: "BT",
    dialCode: "975",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bolivia",
    iso2: "BO",
    dialCode: "591",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    iso2: "BA",
    dialCode: "387",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Botswana",
    iso2: "BW",
    dialCode: "267",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Brazil (Brasil)",
    iso2: "BR",
    dialCode: "55",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    dialCode: "246",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "British Virgin Islands",
    iso2: "VG",
    dialCode: "1284",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Brunei",
    iso2: "BN",
    dialCode: "673",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Bulgaria (България)",
    iso2: "BG",
    dialCode: "359",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    dialCode: "226",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Burundi (Uburundi)",
    iso2: "BI",
    dialCode: "257",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cambodia (កម្ពុជា)",
    iso2: "KH",
    dialCode: "855",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cameroon (Cameroun)",
    iso2: "CM",
    dialCode: "237",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Canada",
    iso2: "CA",
    dialCode: "1",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    iso2: "CV",
    dialCode: "238",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Caribbean Netherlands",
    iso2: "BQ",
    dialCode: "599",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    dialCode: "1345",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Central African Republic (République centrafricaine)",
    iso2: "CF",
    dialCode: "236",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Chad (Tchad)",
    iso2: "TD",
    dialCode: "235",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Chile",
    iso2: "CL",
    dialCode: "56",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "China (中国)",
    iso2: "CN",
    dialCode: "86",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    dialCode: "61",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    dialCode: "61",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Colombia",
    iso2: "CO",
    dialCode: "57",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Comoros (‫جزر القمر‬‎)",
    iso2: "KM",
    dialCode: "269",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    iso2: "CD",
    dialCode: "243",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    iso2: "CG",
    dialCode: "242",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    dialCode: "682",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    dialCode: "506",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Côte d’Ivoire",
    iso2: "CI",
    dialCode: "225",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Croatia (Hrvatska)",
    iso2: "HR",
    dialCode: "385",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cuba",
    iso2: "CU",
    dialCode: "53",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Curaçao",
    iso2: "CW",
    dialCode: "599",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Cyprus (Κύπρος)",
    iso2: "CY",
    dialCode: "357",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Czech Republic (Česká republika)",
    iso2: "CZ",
    dialCode: "420",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Denmark (Danmark)",
    iso2: "DK",
    dialCode: "45",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    dialCode: "253",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Dominica",
    iso2: "DM",
    dialCode: "1767",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Dominican Republic (República Dominicana)",
    iso2: "DO",
    dialCode: "1",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Ecuador",
    iso2: "EC",
    dialCode: "593",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Egypt (‫مصر‬‎)",
    iso2: "EG",
    dialCode: "20",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "El Salvador",
    iso2: "SV",
    dialCode: "503",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    iso2: "GQ",
    dialCode: "240",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Eritrea",
    iso2: "ER",
    dialCode: "291",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Estonia (Eesti)",
    iso2: "EE",
    dialCode: "372",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    dialCode: "251",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    iso2: "FK",
    dialCode: "500",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Faroe Islands (Føroyar)",
    iso2: "FO",
    dialCode: "298",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Fiji",
    iso2: "FJ",
    dialCode: "679",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Finland (Suomi)",
    iso2: "FI",
    dialCode: "358",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "France",
    iso2: "FR",
    dialCode: "33",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "French Guiana (Guyane française)",
    iso2: "GF",
    dialCode: "594",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "French Polynesia (Polynésie française)",
    iso2: "PF",
    dialCode: "689",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Gabon",
    iso2: "GA",
    dialCode: "241",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Gambia",
    iso2: "GM",
    dialCode: "220",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Georgia (საქართველო)",
    iso2: "GE",
    dialCode: "995",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Germany (Deutschland)",
    iso2: "DE",
    dialCode: "49",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Ghana (Gaana)",
    iso2: "GH",
    dialCode: "233",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    dialCode: "350",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Greece (Ελλάδα)",
    iso2: "GR",
    dialCode: "30",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    iso2: "GL",
    dialCode: "299",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Grenada",
    iso2: "GD",
    dialCode: "1473",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    dialCode: "590",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guam",
    iso2: "GU",
    dialCode: "1671",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guatemala",
    iso2: "GT",
    dialCode: "502",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guernsey",
    iso2: "GG",
    dialCode: "44",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guinea (Guinée)",
    iso2: "GN",
    dialCode: "224",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    iso2: "GW",
    dialCode: "245",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Guyana",
    iso2: "GY",
    dialCode: "592",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Haiti",
    iso2: "HT",
    dialCode: "509",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Honduras",
    iso2: "HN",
    dialCode: "504",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Hong Kong (香港)",
    iso2: "HK",
    dialCode: "852",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Hungary (Magyarország)",
    iso2: "HU",
    dialCode: "36",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Iceland (Ísland)",
    iso2: "IS",
    dialCode: "354",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "India (भारत)",
    iso2: "IN",
    dialCode: "91",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Indonesia",
    iso2: "ID",
    dialCode: "62",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Iran (‫ایران‬‎)",
    iso2: "IR",
    dialCode: "98",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Iraq (‫العراق‬‎)",
    iso2: "IQ",
    dialCode: "964",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Ireland",
    iso2: "IE",
    dialCode: "353",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Isle of Man",
    iso2: "IM",
    dialCode: "44",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Israel (‫ישראל‬‎)",
    iso2: "IL",
    dialCode: "972",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Italy (Italia)",
    iso2: "IT",
    dialCode: "39",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Jamaica",
    iso2: "JM",
    dialCode: "1876",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Japan (日本)",
    iso2: "JP",
    dialCode: "81",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Jersey",
    iso2: "JE",
    dialCode: "44",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Jordan (‫الأردن‬‎)",
    iso2: "JO",
    dialCode: "962",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kazakhstan (Казахстан)",
    iso2: "KZ",
    dialCode: "7",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kenya",
    iso2: "KE",
    dialCode: "254",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kiribati",
    iso2: "KI",
    dialCode: "686",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kosovo",
    iso2: "XK",
    dialCode: "383",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kuwait (‫الكويت‬‎)",
    iso2: "KW",
    dialCode: "965",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    iso2: "KG",
    dialCode: "996",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Laos (ລາວ)",
    iso2: "LA",
    dialCode: "856",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Latvia (Latvija)",
    iso2: "LV",
    dialCode: "371",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Lebanon (‫لبنان‬‎)",
    iso2: "LB",
    dialCode: "961",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Lesotho",
    iso2: "LS",
    dialCode: "266",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Liberia",
    iso2: "LR",
    dialCode: "231",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Libya (‫ليبيا‬‎)",
    iso2: "LY",
    dialCode: "218",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    dialCode: "423",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Lithuania (Lietuva)",
    iso2: "LT",
    dialCode: "370",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    dialCode: "352",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Macau (澳門)",
    iso2: "MO",
    dialCode: "853",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    iso2: "MK",
    dialCode: "389",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Madagascar (Madagasikara)",
    iso2: "MG",
    dialCode: "261",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Malawi",
    iso2: "MW",
    dialCode: "265",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Malaysia",
    iso2: "MY",
    dialCode: "60",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Maldives",
    iso2: "MV",
    dialCode: "960",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mali",
    iso2: "ML",
    dialCode: "223",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Malta",
    iso2: "MT",
    dialCode: "356",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    dialCode: "692",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Martinique",
    iso2: "MQ",
    dialCode: "596",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mauritania (‫موريتانيا‬‎)",
    iso2: "MR",
    dialCode: "222",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mauritius (Moris)",
    iso2: "MU",
    dialCode: "230",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mayotte",
    iso2: "YT",
    dialCode: "262",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mexico (México)",
    iso2: "MX",
    dialCode: "52",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Micronesia",
    iso2: "FM",
    dialCode: "691",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Moldova (Republica Moldova)",
    iso2: "MD",
    dialCode: "373",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Monaco",
    iso2: "MC",
    dialCode: "377",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mongolia (Монгол)",
    iso2: "MN",
    dialCode: "976",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Montenegro (Crna Gora)",
    iso2: "ME",
    dialCode: "382",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Montserrat",
    iso2: "MS",
    dialCode: "1664",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Morocco (‫المغرب‬‎)",
    iso2: "MA",
    dialCode: "212",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Mozambique (Moçambique)",
    iso2: "MZ",
    dialCode: "258",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    iso2: "MM",
    dialCode: "95",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Namibia (Namibië)",
    iso2: "NA",
    dialCode: "264",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Nauru",
    iso2: "NR",
    dialCode: "674",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Nepal (नेपाल)",
    iso2: "NP",
    dialCode: "977",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Netherlands (Nederland)",
    iso2: "NL",
    dialCode: "31",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "New Caledonia (Nouvelle-Calédonie)",
    iso2: "NC",
    dialCode: "687",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    dialCode: "64",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    dialCode: "505",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Niger (Nijar)",
    iso2: "NE",
    dialCode: "227",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Nigeria",
    iso2: "NG",
    dialCode: "234",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Niue",
    iso2: "NU",
    dialCode: "683",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    dialCode: "672",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    iso2: "KP",
    dialCode: "850",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    dialCode: "1670",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Norway (Norge)",
    iso2: "NO",
    dialCode: "47",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Oman (‫عُمان‬‎)",
    iso2: "OM",
    dialCode: "968",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Pakistan (‫پاکستان‬‎)",
    iso2: "PK",
    dialCode: "92",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Palau",
    iso2: "PW",
    dialCode: "680",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Palestine (‫فلسطين‬‎)",
    iso2: "PS",
    dialCode: "970",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Panama (Panamá)",
    iso2: "PA",
    dialCode: "507",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    dialCode: "675",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Paraguay",
    iso2: "PY",
    dialCode: "595",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Peru (Perú)",
    iso2: "PE",
    dialCode: "51",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Philippines",
    iso2: "PH",
    dialCode: "63",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Poland (Polska)",
    iso2: "PL",
    dialCode: "48",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Portugal",
    iso2: "PT",
    dialCode: "351",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    dialCode: "1",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Qatar (‫قطر‬‎)",
    iso2: "QA",
    dialCode: "974",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Réunion (La Réunion)",
    iso2: "RE",
    dialCode: "262",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Romania (România)",
    iso2: "RO",
    dialCode: "40",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Russia (Россия)",
    iso2: "RU",
    dialCode: "7",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Rwanda",
    iso2: "RW",
    dialCode: "250",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Barthélemy",
    iso2: "BL",
    dialCode: "590",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Helena",
    iso2: "SH",
    dialCode: "290",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    dialCode: "1869",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Lucia",
    iso2: "LC",
    dialCode: "1758",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Martin (Saint-Martin (partie française))",
    iso2: "MF",
    dialCode: "590",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    iso2: "PM",
    dialCode: "508",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso2: "VC",
    dialCode: "1784",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Samoa",
    iso2: "WS",
    dialCode: "685",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "San Marino",
    iso2: "SM",
    dialCode: "378",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    iso2: "ST",
    dialCode: "239",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    iso2: "SA",
    dialCode: "966",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Senegal (Sénégal)",
    iso2: "SN",
    dialCode: "221",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Serbia (Србија)",
    iso2: "RS",
    dialCode: "381",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Seychelles",
    iso2: "SC",
    dialCode: "248",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    dialCode: "232",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Singapore",
    iso2: "SG",
    dialCode: "65",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Sint Maarten",
    iso2: "SX",
    dialCode: "1721",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Slovakia (Slovensko)",
    iso2: "SK",
    dialCode: "421",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Slovenia (Slovenija)",
    iso2: "SI",
    dialCode: "386",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    dialCode: "677",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Somalia (Soomaaliya)",
    iso2: "SO",
    dialCode: "252",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "South Africa",
    iso2: "ZA",
    dialCode: "27",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "South Korea (대한민국)",
    iso2: "KR",
    dialCode: "82",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "South Sudan (‫جنوب السودان‬‎)",
    iso2: "SS",
    dialCode: "211",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Spain (España)",
    iso2: "ES",
    dialCode: "34",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    iso2: "LK",
    dialCode: "94",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Sudan (‫السودان‬‎)",
    iso2: "SD",
    dialCode: "249",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Suriname",
    iso2: "SR",
    dialCode: "597",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    dialCode: "47",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Swaziland",
    iso2: "SZ",
    dialCode: "268",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Sweden (Sverige)",
    iso2: "SE",
    dialCode: "46",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Switzerland (Schweiz)",
    iso2: "CH",
    dialCode: "41",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Syria (‫سوريا‬‎)",
    iso2: "SY",
    dialCode: "963",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Taiwan (台灣)",
    iso2: "TW",
    dialCode: "886",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    dialCode: "992",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tanzania",
    iso2: "TZ",
    dialCode: "255",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Thailand (ไทย)",
    iso2: "TH",
    dialCode: "66",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    dialCode: "670",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Togo",
    iso2: "TG",
    dialCode: "228",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tokelau",
    iso2: "TK",
    dialCode: "690",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tonga",
    iso2: "TO",
    dialCode: "676",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Trinidad and Tobago",
    iso2: "TT",
    dialCode: "1868",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tunisia (‫تونس‬‎)",
    iso2: "TN",
    dialCode: "216",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Turkey (Türkiye)",
    iso2: "TR",
    dialCode: "90",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    dialCode: "993",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Turks and Caicos Islands",
    iso2: "TC",
    dialCode: "1649",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    dialCode: "688",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "U.S. Virgin Islands",
    iso2: "VI",
    dialCode: "1340",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Uganda",
    iso2: "UG",
    dialCode: "256",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Ukraine (Україна)",
    iso2: "UA",
    dialCode: "380",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    iso2: "AE",
    dialCode: "971",
    isEnabled: true,
    isPreferred: true,
    preferredSortIndex: 1,
  },
  {
    name: "United Kingdom",
    iso2: "GB",
    dialCode: "44",
    isEnabled: true,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "United States",
    iso2: "US",
    dialCode: "1",
    isEnabled: true,
    isPreferred: true,
    preferredSortIndex: 0,
  },
  {
    name: "Uruguay",
    iso2: "UY",
    dialCode: "598",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    iso2: "UZ",
    dialCode: "998",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    dialCode: "678",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Vatican City (Città del Vaticano)",
    iso2: "VA",
    dialCode: "39",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Venezuela",
    iso2: "VE",
    dialCode: "58",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Vietnam (Việt Nam)",
    iso2: "VN",
    dialCode: "84",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    iso2: "WF",
    dialCode: "681",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    iso2: "EH",
    dialCode: "212",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Yemen (‫اليمن‬‎)",
    iso2: "YE",
    dialCode: "967",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Zambia",
    iso2: "ZM",
    dialCode: "260",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    dialCode: "263",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
  {
    name: "Åland Islands",
    iso2: "AX",
    dialCode: "358",
    isEnabled: false,
    isPreferred: false,
    preferredSortIndex: 0,
  },
];

const enabledCountries = countries.filter((country) => country.isEnabled);

// Export countries available for use in dropdown
export const allCountries = countries.map(({ name, iso2, dialCode }) => ({
  name,
  iso2,
  dialCode,
}));

// Export country to be selected by default in dropdown
export const defaultCountry = countries.find((country) => country.name === "United States").iso2;

// Export countries to be displayed in dropdown
export const onlyCountries = enabledCountries.map((country) => country.iso2);

// Export countries to be displayed at top of dropdown
export const preferredCountries = enabledCountries
  .filter((country) => country.isPreferred)
  .sort((a, b) => a.preferredSortIndex - b.preferredSortIndex)
  .map((country) => country.iso2);

export default countries;
