import { startTimeValidation, endTimeValidation } from "@/common/util/formValidations";

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "ShiftDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Shift List",
  modalTitle: "Add Shift",
  resource: {
    url: function (id) {
      return `project/${id.project.id}/plan/shift`;
    },
    transform: function (x) {
      return {
        Start: x.start,
        End: x.end,
      };
    },
  },
  form: {
    title: "New Shift",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(_form, _api) {
      // Do nothing
    },
    submit(form, api, _userId) {
      api
        .post(`project/${form.$filters.filters.project.id}/plan/shift`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      for (let name in this.model) {
        this.model[name] = null;
      }
    },
    model: {
      start: null,
      end: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "time",
          label: "Start Time",
          model: "start",
          validator: ["required", startTimeValidation],
          required: true,
        },
        {
          type: "input",
          inputType: "time",
          label: "End Time",
          model: "end",
          validator: ["required", endTimeValidation],
          required: true,
        },
      ],
    },
  },
};
