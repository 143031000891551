import api from "@/common/api.service";

export default {
  actions: [
    {
      name: "Assign NFC Tag",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "NfcTagAssign",
          params: { id: event.id },
        });
      },
      display: function (item) {
        return !item.Assigned;
      },
    },
    {
      name: "Unassign",
      icon: "location_off",
      handler: function (event) {
        api.post(`admin/nfc/${event.id}/unassign`).then(() => {
          event.Assigned = null;
        });
      },
      display: function (item) {
        return item.Assigned;
      },
    },
  ],
  title: "NFC Tags",
  modalTitle: "Add NFC Tag",
  resource: {
    url: function () {
      return "admin/nfc";
    },
    transform: function (x) {
      return {
        id: x.id,
        UID: x.uid,
        Assigned: x.asset,
      };
    },
  },
  fuseOptions: {
    threshold: 0,
  },
  speedDial: [
    {
      to: "/admin/bulk/nfc/register",
      icon: "nfc",
      label: "Bulk Registration",
    },
  ],
  form: {
    title: "New NFC Tag",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data() {
      // Do nothing
    },
    submit(form, api, _userId) {
      api
        .post(`admin/nfc`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    model: {
      uid: null,
      meta: {
        registeredUsing: "adminDashboard",
      },
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "UID",
          model: "uid",
          pattern: "^[0-9A-F]{8,20}$", // uppercase, hexidecimal, 8-20 char
          validator: ["required", "regexp"],
          required: true,
        },
      ],
    },
  },
};
