import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "This table contains the duration spent in each zone from time of entry until time of next position outside the zone, summed by date of entry. Time in Zone >24 hours indicates that the device remained in the zone overnight.",
  Arabic:
    "يحتوي هذا الجدول على المدة الزمنية في كل سياج جيوغرافي من وقت دخول السياج الجيوغرافي حتى أول  اكتشاف خارج السياج الجيوغرافي، مجمعة حسب تاريخ الدخول. تشير مدة زمنية في سياج جيوغرافي فوق 24 ساعة إلى أن الجهاز بقي في السياج الجيوغرافي بعد نهاية اليوم",
  default:
    "This table contains the duration spent in each zone from time of entry until time of next position outside the zone, summed by date of entry. Time in Zone >24 hours indicates that the device remained in the zone overnight.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/inzonehours`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Time in Zone",
    headers: ["param", "Date", "ID", "Name", "Resource", "Zone", "Hours"],
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Zone: x.zone_name,
            Hours: roundNum(x.hours_in_zone + (x.active_hours_in_zone || 0), 1),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
