import Vue from "vue";

// https://github.com/creativetimofficial/ct-vue-material-dashboard-pro/issues/32
// https://github.com/creativetimofficial/ct-vue-material-dashboard-pro/issues/68
import { format } from "date-fns";
import { NotificationStore } from "../components/NotificationPlugin";
import { getDateFromISO } from "@/common/date.filter";

// have a look at the URL to see different props to pass:
// https://demos.creative-tim.com/vue-material-dashboard-pro/documentation/component-docs/notifications.html
// http://bootstrap-notify.remabledesigns.com/

let notifyService = new Vue({
  methods: {
    safetyAlarm(alarm) {
      // JT: in the future, we can create a function to generate the notification object based on the alarm.alarm property
      NotificationStore.notify({
        message: `${alarm.alarm} - ${alarm.name} @ ${format(
          getDateFromISO(alarm.date),
          "MMM do, h:mm a"
        )}`,
        icon: "warning",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "info",
        // AS: As reference there's a bug with timeout prop: https://github.com/creativetimofficial/ct-vue-material-dashboard-pro/issues/81
        // JT: fixed their bug so you can put timeout here.
        timeout: 30000,
        link: {
          name: "Incident Map", // in the future, will link to the safety overview page
          params: {
            alarm,
          },
        },
      });
    },
    /*extendedHoursDanger(asset) {
      NotificationStore.notify({
        message: `A ${asset.resource_name} of ${
          asset.name
        } is overworked by ${(
          Number(asset.timeonsite) - Number(asset.scheduled_hours)
        ).toFixed(1)} hours`,
        icon: "warning",
        horizontalAlign: "left",
        verticalAlign: "bottom",
        type: "danger"
      });
    },
    incidentWarning(incident) {
      NotificationStore.notify({
        message: `${incident.openeddescription} Incident Occured`,
        icon: "warning",
        horizontalAlign: "left",
        verticalAlign: "bottom",
        type: "warning"
      });
    },*/
    formSubmitSuccess(message = "Success") {
      NotificationStore.notify({
        message,
        icon: "assignment_turned_in",
        horizontalAlign: "left",
        verticalAlign: "bottom",
        type: "success",
      });
    },
    formSubmitFail(message = "Unsuccessful Submission") {
      NotificationStore.notify({
        message,
        icon: "assignment_late",
        horizontalAlign: "left",
        verticalAlign: "bottom",
        type: "warning",
      });
    },
    notify(notificationObj) {
      NotificationStore.notify(notificationObj);
    },
  },
});

export default {
  install: function (Vue) {
    Vue.prototype.$notifyMethods = notifyService;
  },
};
