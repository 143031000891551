import df, { formatDatetime } from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Zone Transitions report displays the timestamp of the first and last reported positions in a Zone for each instance of a Zone being entered.",
  Arabic:
    "يعرض تقرير 'تحركات السياج جيوغرافي' الطابع الزمني للاكتشافات الأولى والأخيرة التي تم الإبلاغ عنها في السياج جيوغرافي لكل مرة تم الدخول إلى سياج جيوغرافي من قبل أحد الموجودات",
  default:
    "The Zone Transitions report displays the timestamp of the first and last reported positions in a Zone for each instance of a Zone being entered.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/zoneTransitions`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Zone Transitions",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Date", "ID", "Name", "Resource", "Zone", "Entered", "Exited", "Hours"],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter(
          (x) =>
            isWorkerOrEquipment(x.resourcetype_name) &&
            ((x.previous_zone !== "NA" &&
              !(x.previous_zone !== x.current_zone && x.current_zone === x.next_zone)) ||
              (x.previous_zone === "NA" && x.next_zone !== x.current_zone))
          // use time as entered and exited when next and previous are NA and duration not null
        )
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Zone:
              x.previous_zone !== "NA" && x.previous_zone === x.current_zone
                ? x.previous_zone
                : x.current_zone,
            Entered:
              x.previous_zone !== "NA" && x.previous_zone === x.current_zone
                ? formatDatetime(x.previous_zone_entry)
                : formatDatetime(x.time),
            Exited:
              x.previous_zone !== "NA" && x.current_zone !== x.next_zone
                ? formatDatetime(x.current_zone_entry)
                : formatDatetime(x.time),
            Hours:
              x.previous_zone !== "NA" && x.previous_zone === x.current_zone
                ? roundNum((x.duration.hours || 0) + (x.duration.minutes || 0) / 60, 1)
                : 0.0,
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
