export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "GlobalAdminCustomerDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Customer List",
  modalTitle: "Add Customer",
  resource: {
    url: function () {
      return "master/customer";
    },
    transform: function (row) {
      return {
        id: row.id,
        Name: row.name,
      };
    },
  },
  form: {
    title: "New Customer",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data() {
      // Do nothing
    },
    submit(form, api, _userId) {
      api
        .post(`master/customer`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch(() => {
          form.$notifyMethods.formSubmitFail();
        });
    },
    model: {
      name: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          validator: ["required", "string"],
          required: true,
        },
      ],
    },
  },
};
