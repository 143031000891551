import * as globalAdminProps from "@/pages/GlobalAdmin/routerProps";

export const globalAdminRoutes = [
  {
    path: "/globalAdmin",
    component: () => import("@/pages/GlobalAdmin/Layout/GlobalAdminLayout.vue"),
    children: [
      {
        path: "",
        name: "GlobalAdminHome",
        components: {
          default: () => import("@/pages/GlobalAdmin/Admin.vue"),
        },
        meta: {
          title: "Global Admin Home",
        },
      },
      {
        path: "hardware",
        component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "device",
            component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "",
                name: "GlobalAdminHardwareList",
                component: () => import("@/pages/GlobalAdmin/Pages/Hardware/Hardware.vue"),
                meta: {
                  title: "Hardware List",
                },
              },
              {
                path: ":id/beacon-detail",
                name: "GlobalAdminHardwareBeaconDetail",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/BeaconDetail.vue"),
                },
                meta: {
                  title: "Beacon Detail",
                },
              },
              {
                path: ":id/detail",
                name: "GlobalAdminHardwareDetail",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/Detail.vue"),
                },
                meta: {
                  title: "Hardware Detail",
                },
              },
              {
                path: ":id/assign",
                name: "GlobalAdminHardwareAssign",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/Assign.vue"),
                },
                meta: {
                  title: "Hardware Assign",
                },
              },
              {
                path: "configure",
                name: "GlobalAdminHardwareConfig",
                components: {
                  default: () =>
                    import("@/pages/GlobalAdmin/Pages/Hardware/Bulk/DeviceConfig/Page.vue"),
                },
                meta: {
                  title: "Hardware Config",
                },
              },
              {
                path: "deploy",
                name: "GlobalAdminBulkHardwareDeploy",
                components: {
                  default: () =>
                    import("@/pages/GlobalAdmin/Pages/Hardware/Bulk/DeviceDeploy/Page.vue"),
                },
                meta: {
                  title: "Hardware Deploy",
                },
              },
              {
                path: "register",
                name: "GlobalAdminRegisterHardware",
                component: () =>
                  import("@/pages/GlobalAdmin/Pages/Hardware/Bulk/DeviceRegister/Page.vue"),
                meta: {
                  title: "Register Hardware",
                },
              },
            ],
          },
          {
            path: "manufacturer",
            name: "GlobalAdminHardwareManufacturer",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return globalAdminProps.manufacturerList;
            },
            meta: {
              title: "Hardware Manufacturer",
            },
          },
          {
            path: "model",
            name: "GlobalAdminHardwareModel",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return globalAdminProps.modelList;
            },
            meta: {
              title: "Hardware Model",
            },
          },
          {
            path: "provider",
            component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "",
                name: "GlobalAdminProviderList",
                component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
                props: function () {
                  return globalAdminProps.providerList;
                },
                meta: {
                  title: "Provider List",
                },
              },
              {
                path: ":id/detail",
                name: "GlobalAdminProviderDetail",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/ProviderDetail.vue"),
                },
                meta: {
                  title: "Provider Detail",
                },
              },
            ],
          },
          {
            path: "sim",
            component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "",
                name: "GlobalAdminSimList",
                component: () => import("@/pages/GlobalAdmin/Pages/Hardware/Sim.vue"),
                meta: {
                  title: "Sim List",
                },
              },
              {
                path: ":id/detail",
                name: "GlobalAdminSimDetail",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/SimDetail.vue"),
                },
                meta: {
                  title: "Sim Detail",
                },
              },
              {
                path: ":id/assign",
                name: "GlobalAdminSimAssign",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/Hardware/SimAssign.vue"),
                },
                meta: {
                  title: "Sim Assign",
                },
              },
              {
                path: "assign",
                name: "GlobalAdminSimDeploy",
                components: {
                  default: () =>
                    import("@/pages/GlobalAdmin/Pages/Hardware/Bulk/AssignSims/Page.vue"),
                },
                meta: {
                  title: "Sim Deploy",
                },
              },
              {
                path: "register",
                name: "GlobalAdminRegisterSim",
                component: () =>
                  import("@/pages/GlobalAdmin/Pages/Hardware/Bulk/RegisterSims/Page.vue"),
                meta: {
                  title: "Register Sim",
                },
              },
            ],
          },
          {
            path: "log",
            name: "GlobalAdminHardwareLog",
            components: {
              default: () => import("@/pages/GlobalAdmin/Pages/Hardware/Log.vue"),
            },
            meta: {
              title: "Hardware Log",
            },
          },
        ],
      },
      {
        path: "software",
        component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "tenant",
            name: "GlobalAdminTenantList",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return globalAdminProps.tenantList;
            },
            meta: {
              title: "Tenant List",
            },
          },
        ],
      },
      {
        path: "user",
        component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "",
            name: "GlobalAdminUserList",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return globalAdminProps.userList;
            },
            meta: {
              title: "User List",
            },
          },
          {
            path: ":id",
            component: () => import("@/pages/GlobalAdmin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "detail",
                name: "GlobalAdminUserDetail",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/User/Detail.vue"),
                },
                meta: {
                  title: "User Detail",
                },
              },
              {
                path: "assign",
                name: "GlobalAdminUserTenantAssign",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/User/AssignTenant.vue"),
                },
                meta: {
                  title: "User Tenant Assign",
                },
              },
              {
                path: "edit",
                name: "GlobalAdminUserEdit",
                components: {
                  default: () => import("@/pages/GlobalAdmin/Pages/User/Edit.vue"),
                },
                meta: {
                  title: "User Edit",
                },
              },
            ],
          },
        ],
      },
      {
        path: "customer",
        name: "GlobalAdminCustomers",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return globalAdminProps.customerList;
        },
        meta: {
          title: "Customers",
        },
      },
    ],
  },
];
