import compareString from "@/common/util/compareString";

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "GlobalAdminTenantDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Tenant List",
  modalTitle: "Add Tenant",
  defaultSort: "Name",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "master/tenant";
    },
    transform: function (row) {
      return {
        id: row.id,
        Customer: row.customer.customer_name,
        Name: row.name,
        DB: row.db,
      };
    },
  },
  form: {
    title: "New Tenant",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(form, api) {
      api.get(`master/customer`).then((res) => {
        form.form.schema.fields.find((x) => x.model === "customer").values = function () {
          return res.data
            .map((x) => ({
              id: x.id,
              name: x.name,
            }))
            .sort((a, b) => compareString(a.name, b.name));
        };
      });
    },
    submit(form, api, _userId) {
      api
        .post(`master/tenant`, {
          name: this.model.name,
          customer: this.model.customer,
        })
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch(() => {
          form.$notifyMethods.formSubmitFail();
        });
    },
    model: {
      name: null,
      customer: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "select",
          label: "Customer",
          model: "customer",
          selectOptions: {
            noneSelectedText: "Select Customer...",
          },
          values: [],
          validator: "required",
          required: true,
        },
      ],
    },
  },
};
