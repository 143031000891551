import Vue from "vue";
import axios from "axios";
import jwt from "jsonwebtoken";
import { API_URL } from "@/common/config";
import isNumber from "@/common/util/isNumber";
import { InMemoryCache } from "./cache";
import * as ClientStorage from "./storage";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;
const cache = new InMemoryCache().enclosedCache;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance;
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: null,
        error: null,
        domain: null,
        client_id: null,
        audience: null,
        logoutUrl: null,
        refreshing: false,
        authInterval: null,
      };
    },
    computed: {
      roles: function () {
        let roles = [];

        if (this.activeTenant) {
          roles = roles.concat(this.activeTenant.roles);
        }

        if (this.user.admin) {
          roles = roles.concat(this.user.admin.roles);
        }

        return roles;
      },
      activeTenant: function () {
        if (!this.user || !this.user.tenants) {
          return null;
        }

        return this.user.tenants[0];
      },
      activeCustomer: function () {
        if (!this.activeTenant || !this.activeTenant.customer) {
          return null;
        }

        return this.activeTenant.customer;
      },
      settings: function () {
        if (!this.activeTenant) {
          return {};
        }

        return this.activeTenant.settings || {};
      },
      isGlobalAdmin: function () {
        if (!this.roles) {
          return false;
        }

        return this.roles.includes("GlobalAdmin");
      },
      isAdmin: function () {
        if (!this.roles) {
          return false;
        }

        return this.roles.includes("Admin");
      },
      isManager: function () {
        if (!this.roles) {
          return false;
        }

        return this.roles.includes("Manager");
      },
      hasProjects: function () {
        if (!this.user || !this.user.projects) {
          return false;
        }

        return this.user.projects.length > 0;
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.domain = options.domain;
      this.client_id = options.clientId;
      this.audience = options.audience;
      this.redirect_uri = redirectUri;
      this.logoutUrl = options.logoutUrl;

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          // handle the redirect and retrieve tokens
          //const { appState } = await this.auth0Client.handleRedirectCallback();

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)

          onRedirectCallback();
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    methods: {
      login({ email, password }) {
        return axios
          .post(`${API_URL}login`, { email, password }, { withCredentials: true })
          .then(this.getLocalProfile)
          .then(this.handleAuth);
      },
      getLocalProfile: async function (res) {
        // if there are tenants, get the local profile instead of global
        if (res.data.auth && res.data.user.tenants.length > 0) {
          const localProfile = await axios.get(`${API_URL}tenant/user/profile`, {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
            },
          });

          res.data.user = localProfile.data;
        }

        return res;
      },
      handleAuth: async function (res) {
        if (res.data.auth) {
          this.user = res.data.user;
          this.auth = res.data.auth;

          const decoded = await jwt.decode(res.data.token);

          cache.save({
            expires_in: decoded.exp - decoded.iat,
            refreshAt: new Date((decoded.exp - 300) * 1000).valueOf(),
            token: res.data.token,
            decodedToken: decoded,
            user: res.data.user,
            client_id: this.client_id,
            audience: this.audience,
            scope: "token",
          });

          this.isAuthenticated = res.data.auth;
          localStorage.setItem("og_core_auth", "true");
          ClientStorage.save("og_core_auth", true, {
            expiresAt: new Date(decoded.exp * 1000),
          });

          if (isNumber(this.authInterval)) {
            clearInterval(this.authInterval);
          }

          this.authInterval = setInterval(this.checkAuthRefresh, 1000 * 10);
        }

        return res.data.token;
      },
      checkAuthCookie() {
        const authCookie = ClientStorage.get("og_core_auth");

        return authCookie;
      },
      checkAuthRefresh() {
        const isAuth = ClientStorage.get("og_core_auth");

        if (!isAuth) {
          window.location.pathname = "/logout";
        }
      },
      refreshSession() {
        return axios
          .post(`${API_URL}session/refresh`, {}, { withCredentials: true })
          .then(this.getLocalProfile)
          .then(this.handleAuth);
      },
      validateQueryToken(token, path) {
        const type = path.slice(1);

        return axios.post(
          `${API_URL}${type}`,
          {
            token,
          },
          { withCredentials: true }
        );
      },
      completeSignup(body, path) {
        const type = path.slice(1);

        return axios
          .post(`${API_URL}${type}/complete`, body, {
            withCredentials: true,
          })
          .then(this.getLocalProfile)
          .then(this.handleAuth);
      },
      resetPassword(body) {
        return axios.post(`${API_URL}user/resetpassword`, body, {
          withCredentials: true,
        });
      },
      getTokenSilently() {
        const key = {
          client_id: this.client_id,
          audience: this.audience,
          scope: "token",
        };

        const cacheEntry = cache.get(key);

        if (!this.refreshing && (!cacheEntry.refreshAt || cacheEntry.refreshAt <= Date.now())) {
          // set refreshing flag and clear it after 5 seconds
          // to prevent paraleller refresh requests causing CORS errors
          this.refreshing = true;

          setTimeout(() => {
            this.refreshing = false;
          }, 5000);

          return this.refreshSession();
        }

        return new Promise((resolve, reject) => {
          if (cacheEntry && cacheEntry.token) {
            resolve(cacheEntry.token);
          } else {
            reject("No token found");
          }
        });
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        return axios.post(`${API_URL}logout`, {}, { withCredentials: true }).then(() => {
          cache.clear();
          this.user = null;
          this.isAuthenticated = false;
          localStorage.removeItem("og_core_auth");
          ClientStorage.remove("og_core_auth");

          if (isNumber(this.authInterval)) {
            clearInterval(this.authInterval);
          }
        });
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const LocalAuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
