import { FEATURES } from "@/common/config";
import validatePhoneNumber from "@/common/util/validatePhoneNumber";

const initialModel = {
  name: null,
  email: null,
  tenant: null,
  roles: [],
  phone: "",
};

/**
 * These variables are used to enable/disable initial validation of all form fields.
 * If the phoneNumbers feature is enabled, validation is disabled until the phone input field is ready.
 * When the vue-tel-input component initializes, it (1) sets the field value and (2) formats it.
 */
const initialValidationSettings = {
  formValidationIsEnabled: !FEATURES.phoneNumbers,
  phoneChangeCount: 0,
};
let { formValidationIsEnabled, phoneChangeCount } = initialValidationSettings;
const minCountFormEnable = 2;

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "GlobalAdminUserDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "User",
  modalTitle: "Add User",
  defaultSort: "Email",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "master/user";
    },
    transform: function (row) {
      return {
        id: row.id,
        Name: row.name,
        Email: row.email,
        Tenant: row.tenants
          .map((x) => x.name)
          .reduce((a, v) => `${a}, ${v}`, "")
          .substring(1),
        Role:
          row.admin && row.admin.roles.length > 0
            ? row.admin.roles.reduce((a, v) => `${a}, ${v}`, "").substring(1)
            : "User",
      };
    },
  },
  form: {
    title: "New User",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    getFormValidationIsEnabled() {
      return formValidationIsEnabled;
    },
    async data(form, api) {
      api.get(`master/tenant`).then((res) => {
        form.form.schema.fields.find((x) => x.model === "tenant").values = function () {
          return res.data
            .map((x) => {
              return {
                id: x.id,
                name: x.name,
              };
            })
            .filter((x) => x.id)
            .sort((a, b) => (b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1));
        };
      });
    },
    submit(form, api, _userId) {
      const url = "master/user";
      const toSubmit = {
        name: this.model.name,
        email: this.model.email,
        tenants: [
          {
            tenant_id: this.model.tenant,
            roles: this.model.roles,
          },
        ],
        phone: this.model.phone === "" ? null : this.model.phone,
      };

      api
        .post(url, toSubmit)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .then(() => {
          if (form.id) {
            return form.$router.push({
              name: "GlobalAdminUserList",
            });
          }

          return;
        })
        .catch(() => {
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      ({ formValidationIsEnabled, phoneChangeCount } = initialValidationSettings);
      this.model = { ...initialModel };
    },
    model: { ...initialModel },
    schema: {
      fields: [
        {
          type: "select",
          label: "Tenant",
          model: "tenant",
          selectOptions: {
            noneSelectedText: "Select Tenant...",
          },
          values: [],
          required: true,
          validator: "required",
        },
        {
          type: "checklist",
          listBox: true,
          label: "Roles",
          model: "roles",
          values: ["Manager", "Admin"],
          required: true,
          validator: ["required", "array"],
          min: 0,
        },
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          required: true,
          validator: ["required", "string"],
        },
        {
          type: "input",
          inputType: "text",
          label: "Email",
          model: "email",
          required: true,
          validator: ["required", "email"],
        },
        {
          type: "tel-input",
          label: "Mobile Phone",
          model: "phone",
          visible: FEATURES.phoneNumbers,
          validator: [
            function (value, _field, _model) {
              return validatePhoneNumber(value);
            },
          ],
          validateDebounceTime: 1,
          onChanged: function (_model, _newVal, _oldVal, _field) {
            phoneChangeCount++;

            if (phoneChangeCount === minCountFormEnable) {
              formValidationIsEnabled = true;
            }
          },
        },
      ],
    },
  },
};
