/**
 * Map object containing default props for marker icons used in map components
 */

const markerIcons = new Map([
  // Key-value pair format: [ Resource Type, { Marker Icon Props } ]
  [
    "Equipment",
    {
      icon: "fa-truck-pickup",
      markerColor: "green",
      prefix: "fa",
    },
  ],
  [
    "Tool",
    {
      icon: "fa-wrench",
      markerColor: "blue",
      prefix: "fa",
    },
  ],
  [
    "Worker",
    {
      icon: "fa-user",
      markerColor: "red",
      prefix: "fa",
    },
  ],
]);

export const defaultMarkerIcon = markerIcons.get("Worker");

export default markerIcons;
