import api from "@/common/api.service";

export default {
  actions: [
    {
      name: "Assign Device",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "Admin Device Assign",
          params: { id: event.id },
        });
      },
      display: function (item) {
        return !item.Assigned;
      },
    },
    {
      name: "Unassign",
      icon: "location_off",
      handler: function (event) {
        api.post(`admin/hardware/${event.id}/unassign`).then(() => {
          event.Assigned = null;
        });
      },
      display: function (item) {
        return item.Assigned;
      },
    },
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "Admin Device Detail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Devices",
  modalTitle: "Add Device",
  defaultSort: "Last Report UTC",
  defaultSortOrder: "desc",
  resource: {
    url: function () {
      return "admin/hardware";
    },
    transform: function (x) {
      return {
        id: x.id,
        Serial: x.serial,
        Assigned: x.asset,
        "Last Report UTC": x.last_report ? x.last_report.substr(0, 16).replace("T", " ") : null,
        Battery: x.battery,
      };
    },
  },
};
