import * as adminProps from "@/pages/Admin/routerProps";

export const adminRoutes = [
  {
    path: "/admin",
    component: () => import("@/pages/Admin/Layout/AdminLayout.vue"),
    children: [
      {
        path: "/",
        name: "Admin Home",
        components: {
          default: () => import("@/pages/Admin/Admin.vue"),
        },
      },
      {
        path: "hardware",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "device",
            component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "/",
                name: "Admin Device",
                component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
                props: function () {
                  return adminProps.deviceList;
                },
                meta: {
                  title: "Admin Devices",
                },
              },
              {
                path: ":id/detail",
                name: "Admin Device Detail",
                component: () => import("@/pages/Admin/Pages/Hardware/Detail.vue"),
                meta: {
                  title: "Device Details",
                },
              },
              {
                path: ":id/assign",
                name: "Admin Device Assign",
                components: {
                  default: () => import("@/pages/Admin/Pages/Hardware/Devices/Assign.vue"),
                },
                meta: {
                  title: "Assign Device",
                },
              },
            ],
          },
          {
            path: "beacon",
            component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "/",
                name: "Admin Beacon",
                component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
                props: function () {
                  return adminProps.beaconList;
                },
                meta: {
                  title: "Admin Beacons",
                },
              },
              {
                path: ":id/detail",
                name: "Admin Beacon Detail",
                component: () => import("@/pages/Admin/Pages/Hardware/Detail.vue"),
                props: {
                  isBeacon: true,
                },
                meta: {
                  title: "Beacon Details",
                },
              },
              {
                path: ":id/assign",
                name: "Admin Beacon Assign",
                components: {
                  default: () => import("@/pages/Admin/Pages/Hardware/Beacons/Assign.vue"),
                },
                meta: {
                  title: "Assign Beacon",
                },
              },
              {
                path: ":id/assign-to-point",
                name: "Admin Beacon Assign Point",
                components: {
                  default: () => import("@/pages/Admin/Pages/Hardware/Beacons/AssignToPoint.vue"),
                },
                meta: {
                  title: "Assign Beacon to Point",
                },
              },
            ],
          },
        ],
      },
      {
        path: "nfc",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin NFC Tags",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return adminProps.nfcList;
            },
            meta: {},
          },
          {
            path: ":id/assign",
            name: "NfcTagAssign",
            components: {
              default: () => import("@/pages/Admin/Pages/NfcTags/Assign.vue"),
            },
            meta: {
              title: "Assign NFC Tags",
            },
          },
        ],
      },
      {
        path: "scanners",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Scanners",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return adminProps.scannerList;
            },
          },
          {
            path: "/hardware/:id/install",
            name: "Scanner Install",
            components: {
              default: () => import("@/pages/Admin/Pages/Scanners/Install.vue"),
            },
          },
        ],
      },
      {
        path: "assets",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Assets",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function (route) {
              if (route.query.type === "equipment" || route.query.type === "Equipment") {
                return adminProps.equipmentList;
              } else if (route.query.type === "tool" || route.query.type === "Tool") {
                return adminProps.toolList;
              } else {
                return adminProps.assetList;
              }
            },
          },
          {
            path: ":id/update",
            name: "Update Asset",
            component: () => import("@/pages/Admin/Pages/Assets/AssetUpdate.vue"),
            props: function (route) {
              if (route.query.type === "equipment" || route.query.type === "Equipment") {
                return adminProps.equipmentList;
              } else if (route.query.type === "tool" || route.query.type === "Tool") {
                return adminProps.toolList;
              } else {
                return adminProps.assetList;
              }
            },
          },
          {
            path: ":id/detail",
            name: "Admin Asset Detail",
            components: {
              default: () => import("@/pages/Admin/Pages/Assets/AssetDetail.vue"),
            },
            meta: {
              title: "Asset Details",
            },
          },
          {
            path: ":id/assign-device",
            name: "Admin Asset Assign Device",
            components: {
              default: () => import("@/pages/Admin/Pages/Assets/AssetAssignDevice.vue"),
            },
            meta: {
              title: "Assign Device",
            },
          },
          {
            path: ":id/assign-nfc-tag",
            name: "Admin Asset Assign NFC Tag",
            components: {
              default: () => import("@/pages/Admin/Pages/Assets/AssetAssignNfcTag.vue"),
            },
            meta: {
              title: "Assign NFC Tag",
            },
          },
          {
            path: ":id/assign-to-project",
            name: "Admin Asset Assign Project",
            components: {
              default: () => import("@/pages/Admin/Pages/Assets/AssetAssignProject.vue"),
            },
            meta: {
              title: "Assign Asset to Project",
            },
          },
        ],
      },
      {
        path: "contractors",
        name: "Admin Contractors",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return adminProps.contractorList;
        },
      },
      {
        path: "users",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Users",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return adminProps.userList;
            },
          },
          {
            path: ":id",
            component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "/",
                name: "Admin User Detail",
                components: {
                  default: () => import("@/pages/Admin/Pages/Users/Detail.vue"),
                },
                meta: {
                  title: "User Details",
                },
              },
              {
                path: "edit",
                name: "Admin User Edit",
                component: () => import("@/pages/Admin/Pages/Users/Edit.vue"),
                meta: {
                  title: "Edit User",
                },
              },
            ],
          },
        ],
      },
      {
        path: "costcode",
        name: "Admin Cost Code",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return adminProps.costCodeList;
        },
      },
      {
        path: "rescode",
        name: "Admin Resource Code",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return adminProps.resourceCodeList;
        },
      },
      {
        path: "resources",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Resources",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return adminProps.resourceList;
            },
          },
          {
            path: ":id",
            component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "edit",
                name: "Admin Resource Edit",
                component: () => import("@/pages/Admin/Pages/Resources/Edit.vue"),
                meta: {
                  title: "Edit Resource",
                },
              },
            ],
          },
        ],
      },
      {
        path: "clients",
        name: "Admin Clients",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return adminProps.clientList;
        },
      },
      {
        path: "settings",
        name: "Admin Settings",
        component: () => import("@/pages/Admin/Pages/Settings/Settings.vue"),
      },
      {
        path: "projects",
        name: "Admin Projects",
        component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
        props: function () {
          return adminProps.projectList;
        },
      },
      {
        path: "bulk",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "resource/register",
            name: "AdminBulkResourceRegister",
            component: () => import("@/pages/Admin/Pages/Bulk/ResourceRegister/Page.vue"),
          },
          {
            path: "contractor/register",
            name: "AdminBulkContractorRegister",
            component: () => import("@/pages/Admin/Pages/Bulk/ContractorRegister/Page.vue"),
          },
          {
            path: "asset/register",
            component: () => import("@/pages/Admin/Pages/Bulk/AssetRegister/Page.vue"),
          },
          {
            path: "nfc/register",
            component: () => import("@/pages/Admin/Pages/Bulk/NfcTagRegister/Page.vue"),
          },
          {
            path: "asset/assigndevice",
            component: () => import("@/pages/Admin/Pages/Bulk/AssetDeviceAssign/Page.vue"),
          },
          {
            path: "asset/assignnfctag",
            component: () => import("@/pages/Admin/Pages/Bulk/AssetNfcTagAssign/Page.vue"),
          },
          {
            path: "asset/assignproject",
            component: () => import("@/pages/Admin/Pages/Bulk/AssetProjectAssign/Page.vue"),
          },
        ],
      },
      {
        path: "log",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Event Log",
            component: () => import("@/pages/Admin/Pages/StateLog/StateLogEventList.vue"),
            props: function () {
              return adminProps.deviceList;
            },
            meta: {
              title: "Event Log",
            },
          },
        ],
      },
      {
        path: "webhooks",
        component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
        children: [
          {
            path: "/",
            name: "Admin Webhooks",
            component: () => import("@/components/_ottogee/Tables/TableForm.vue"),
            props: function () {
              return adminProps.webhookList;
            },
          },
          {
            path: ":id",
            component: () => import("@/pages/Admin/Layout/EmptyLayout.vue"),
            children: [
              {
                path: "/",
                name: "Admin Webhook Detail",
                components: {
                  default: () => import("@/pages/Admin/Pages/Webhooks/Detail.vue"),
                },
                meta: {
                  title: "Webhook Details",
                },
              },
              {
                path: "edit",
                name: "Admin Webhook Edit",
                component: () => import("@/pages/Admin/Pages/Webhooks/Edit.vue"),
                meta: {
                  title: "Edit Webhook",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
