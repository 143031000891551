import df, { formatDatetime } from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Time Off Site report shows the time spent leaving and returning to site in the same day.",
  Arabic:
    "يعرض تقرير 'الوقت خارج الموقع' الوقت المستغرق في مغادرة الموقع والعودة إليه في نفس اليوم",
  default:
    "The Time Off Site report shows the time spent leaving and returning to site in the same day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/timeOffSite`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Time Off Site",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Date", "ID", "Name", "Resource", "Departure", "Arrival", "Hours"],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "Detail2",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Departure: x.departure ? formatDatetime(x.departure) : "NA",
            Arrival: x.arrival ? formatDatetime(x.arrival) : "NA",
            Hours: roundNum(x.hours_off_site, 1),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
