<template>
  <div class="telephone-input">
    <vue-tel-input
      ref="telephoneInput"
      v-model="value"
      :all-countries="$options.allCountries"
      :default-country="$options.defaultCountry"
      mode="international"
      :only-countries="$options.onlyCountries"
      :preferred-countries="$options.preferredCountries"
      @close="isOpenDropdown = false"
      @open="isOpenDropdown = true"
    >
      <template #arrow-icon>
        <span>{{ isOpenDropdown ? "▲" : "▼" }}</span>
      </template>
    </vue-tel-input>
  </div>
</template>

<script>
import Vue from "vue";
import { abstractField } from "vue-form-generator";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import {
  allCountries,
  defaultCountry,
  onlyCountries,
  preferredCountries,
} from "@/common/reference-data/telephoneCountries";

Vue.use(VueTelInput);

export default {
  name: "TelephoneInput",
  mixins: [abstractField],
  allCountries,
  defaultCountry,
  onlyCountries,
  preferredCountries,
  data: function () {
    return {
      isOpenDropdown: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.telephone-input {
  width: 100%;
}
</style>
