<template>
  <div class="multiselect-field">
    <multiselect
      :id="selectOptions.id"
      v-model="selectedOptions"
      :allow-empty="selectOptions.allowEmpty"
      :block-keys="selectOptions.blockKeys"
      :clear-on-select="selectOptions.clearOnSelect"
      :close-on-select="selectOptions.closeOnSelect"
      :custom-label="customLabel"
      :deselect-group-label="selectOptions.deselectGroupLabel"
      :deselect-label="selectOptions.deselectLabel"
      :disabled="disabled"
      :group-label="selectOptions.groupLabel"
      :group-select="selectOptions.groupSelect"
      :group-values="selectOptions.groupValues"
      :hide-selected="selectOptions.hideSelected"
      :internal-search="selectOptions.internalSearch"
      :label="selectOptions.label || null"
      :limit="selectOptions.limit"
      :limit-text="selectOptions.limitText"
      :loading="selectOptions.loading"
      :max="schema.max || null"
      :max-height="selectOptions.maxHeight"
      :multiple="selectOptions.multiple"
      :name="selectOptions.name"
      :open-direction="selectOptions.openDirection"
      :option-height="selectOptions.optionHeight"
      :options="options"
      :options-limit="selectOptions.optionsLimit"
      :placeholder="schema.placeholder"
      :preselect-first="selectOptions.preselectFirst"
      :preserve-search="selectOptions.preserveSearch"
      :reset-after="selectOptions.resetAfter"
      :searchable="selectOptions.searchable"
      :select-group-label="selectOptions.selectGroupLabel"
      :select-label="selectOptions.selectLabel"
      :selected-label="selectOptions.selectedLabel"
      :show-labels="selectOptions.showLabels"
      :show-no-results="selectOptions.showNoResults"
      :show-pointer="selectOptions.showPointer"
      :tabindex="selectOptions.tabindex"
      :tag-placeholder="selectOptions.tagPlaceholder"
      :tag-position="selectOptions.tagPosition"
      :taggable="selectOptions.taggable"
      :track-by="selectOptions.trackBy || null"
      @input="handleInput"
      @search-change="handleSearchChange"
      @tag="handleTag"
    >
      <template
        v-if="shouldUseOptionSlot"
        slot="option"
        slot-scope="props"
      >
        <IconOption
          v-if="isIconMultiselect"
          :description="props.option.description"
          :icon="props.option.icon"
        />
      </template>
      <template
        v-if="shouldUseMaxElementsSlot"
        slot="maxElements"
      >
        {{ selectOptions.maxElements }}
      </template>
      <template
        v-if="shouldUseNoResultSlot"
        slot="noResult"
      >
        {{ selectOptions.noResult }}
      </template>
      <template
        v-if="shouldUseSingleLabelSlot"
        slot="singleLabel"
        slot-scope="props"
      >
        <IconSingleLabel
          v-if="isIconMultiselect"
          :description="props.option.description"
          :icon="props.option.icon"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

import isNumber from "@/common/util/isNumber";

export default {
  name: "MultiselectField",
  components: {
    IconOption: () => import("./IconMultiselect/IconOption.vue"),
    IconSingleLabel: () => import("./IconMultiselect/IconSingleLabel.vue"),
  },
  mixins: [abstractField],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formOptions: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    vfg: {
      type: Object,
      required: true,
    },
  },
  defaultSelectedOptions: [],
  data() {
    return {
      selectedOptions: this.$options.defaultSelectedOptions,
    };
  },
  computed: {
    customLabel() {
      const hasCustomLabel = typeof this.selectOptions?.customLabel === "function";

      return hasCustomLabel ? this.selectOptions.customLabel : undefined;
    },
    defaultValue() {
      return this.selectOptions?.value ?? null;
    },
    isIconMultiselect() {
      return this.schema.customProps?.isIconMultiselect ?? false;
    },
    options() {
      const { values } = this.schema;

      return typeof values === "function" ? values.apply(this, [this.model, this.schema]) : values;
    },
    selectOptions() {
      return this.schema.selectOptions ?? {};
    },
    shouldUseMaxElementsSlot() {
      return this.schema.customProps?.slots?.maxElements ?? false;
    },
    shouldUseNoResultSlot() {
      return this.schema.customProps?.slots?.noResult ?? false;
    },
    shouldUseOptionSlot() {
      return this.schema.customProps?.slots?.option ?? false;
    },
    shouldUseSingleLabelSlot() {
      return this.schema.customProps?.slots?.singleLabel ?? false;
    },
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        this.setSelectedOptions(val);
      },
      immediate: true,
    },
    options: {
      handler: function () {
        if (this.defaultValue === null) {
          // Reset selected options
          this.selectedOptions = this.$options.defaultSelectedOptions;

          return;
        }

        this.setSelectedOptions(this.defaultValue);
      },
      deep: true,
    },
  },
  methods: {
    handleInput(value, _id) {
      this.value = value;
    },
    handleSearchChange(searchQuery, id) {
      const { onSearch } = this.selectOptions;

      if (typeof onSearch === "function") {
        onSearch(searchQuery, id, this.options);
      }
    },
    handleTag(newTag, id) {
      const { onNewTag } = this.selectOptions;

      if (typeof onNewTag === "function") {
        onNewTag(newTag, id, this.options, this.value);
      }
    },
    setSelectedOptions(val) {
      const valType = {
        isArray: Array.isArray(val),
        isNumber: isNumber(val),
        isObject: typeof val === "object" && !Array.isArray(val) && val !== null,
        isString: typeof val === "string",
      };

      if (valType.isArray) {
        this.selectedOptions = val;
      }

      if (valType.isNumber || valType.isObject || valType.isString) {
        this.selectedOptions = [val];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-field {
  width: 100%;

  :deep(.multiselect) {
    min-height: 46.5px;

    .multiselect {
      &__placeholder {
        margin-bottom: 0;
        padding-top: 0;
      }

      &__select {
        height: 44.5px;
      }

      &__tags {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 46.5px;
        padding-top: 0;
        padding-left: 12px;

        &-wrap {
          line-height: 1;
        }
      }

      &__tag {
        margin-top: 5px;
      }

      &__spinner {
        height: 41.5px;
      }

      &__input {
        margin-top: 5px;
        padding-left: 0;
        border-radius: initial;
        line-height: 1;
      }

      &__single {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;
      }
    }
  }
}
</style>
