import { getInstance } from "./index";

export const localAuthGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (to.meta.public) {
      return next();
    }

    if (to.meta.validateToken) {
      return authService
        .validateQueryToken(to.query.token, to.path)
        .then(() => next())
        .catch(() => next({ path: "/login" }));
    }

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Otherwise, log in
    next({ path: "/login" });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const tenantGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (to.meta.public) {
      return next();
    }

    // If the user is authenticated, continue with the route
    if (to.meta.requiresTenant && !authService.activeTenant) {
      return authService.isGlobalAdmin
        ? next({ path: "/globalAdmin" })
        : next({ path: "/profile" });
    }

    // Otherwise, log in
    next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const roleGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (to.meta.public) {
      return next();
    }

    // If the user is authenticated, continue with the route
    if (to.meta.requiresGlobalAdmin && !authService.isGlobalAdmin) {
      return authService.activeTenant ? next({ path: "/" }) : next({ path: "/profile" });
    }

    // TODO: update auth guard
    if (to.meta.requiresAnalyzer && !authService.isAnalyzer && authService.isDeployer) {
      return authService.activeTenant ? next({ path: "/subject" }) : next({ path: "/profile" });
    }

    // if user has no roles, go to user profile
    if (
      (to.meta.requiresAnyRole ||
        to.meta.requiresAnalyzer ||
        to.meta.requiresGlobalAdmin ||
        to.meta.requiresLocalAdmin ||
        to.meta.requiresDeployer ||
        to.meta.requiresUploader) &&
      authService.roles.length === 0
    ) {
      if (to.name === "UserProfile") {
        next();
      } else {
        next({ name: "UserProfile" });
      }
    }

    // Otherwise, log in
    next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
