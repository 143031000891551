import { format } from "date-fns";
import df from "@/common/date.filter";
import { startDateValidation, endDateValidation } from "@/common/util/formValidations";
import compareString from "@/common/util/compareString";

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "ActivityDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
    {
      name: "Update",
      icon: "edit",
      handler: function (event, router) {
        router.push({
          name: "ActivityUpdate",
          params: { id: event.id },
        });
      },
      display: function (event, router, filters, auth) {
        return auth.isAdmin || auth.isManager;
      },
    },
  ],
  title: "Activities",
  modalTitle: "Add Activity",
  resource: {
    url: function (id) {
      return `project/${id.project.id}/plan/activity`;
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: x.name,
        "Planned Start": df(x.planned_start),
        "Planned End": df(x.planned_end),
        "% Complete": x.percent_complete,
      };
    },
  },
  form: {
    title: "New Activity",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(form, api) {
      api.query(`project/${form.$filters.filters.project.id}/location/location`).then((res) => {
        form.form.schema.fields.find((x) => x.model === "locations").values = function () {
          return res.data
            .map((x) => ({
              value: x.id,
              name: x.name,
            }))
            .sort((a, b) => compareString(a.name, b.name));
        };
      });
    },
    submit(form, api, userId) {
      this.model.manager = userId;

      api
        .post(`project/${form.$filters.filters.project.id}/plan/activity`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      for (let name in this.model) {
        this.model[name] = null;
      }
    },
    model: {
      name: null,
      quantity: null,
      start: null,
      end: null,
      labor: null,
      laborCost: null,
      equipment: null,
      equipmentCost: null,
      locations: null,
      manager: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Activity Name",
          model: "name",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Quantity",
          model: "quantity",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
        {
          type: "input",
          inputType: "date",
          label: "Start Date",
          model: "start",
          required: true,
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["date", "required", startDateValidation],
          min: new Date(),
          onChanged: function (model, newVal, _oldVal, _field) {
            model.start = format(newVal, "yyyy-MM-dd");
          },
        },
        {
          type: "input",
          inputType: "date",
          label: "End Date",
          model: "end",
          required: true,
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["date", "required", endDateValidation],
          min: new Date(),
          onChanged: function (model, newVal, _oldVal, _field) {
            model.end = format(newVal, "yyyy-MM-dd");
          },
        },
        {
          type: "input",
          inputType: "number",
          label: "Expected Man Hours",
          model: "labor",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Expected Labor Cost",
          model: "laborCost",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Expected Equipment Hours",
          model: "equipment",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Expected Equipment Cost",
          model: "equipmentCost",
          styleClasses: "col-md-6 md-layout-item md-size-50",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
        {
          type: "checklist",
          listBox: true,
          model: "locations",
          label: "Locations",
          values: [],
          styleClasses: "col-md-6 md-layout-item md-size-100",
          validator: ["required", "array"],
          required: true,
          min: 1,
        },
      ],
    },
  },
};
