import schemas from "@/common/schemas";

const { validatePhone } = schemas.utils;

export default (phoneNumber, isRequired = false) => {
  const isNull = phoneNumber === null;
  const isEmptyString = phoneNumber === "";

  if (!isRequired && (isNull || isEmptyString)) {
    return [];
  }

  const { error } = validatePhone(phoneNumber);

  if (error) {
    return ["Please enter a valid phone number"];
  }

  return [];
};
