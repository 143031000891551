import { formatDatetime, getDuration } from "@/common/date.filter";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English: "The Contacts report displays each instance of a Worker being nearby another Worker.",
  Arabic: "يعرض تقرير 'الاختلاط' كل حالة لعامل بالقرب من عامل آخر",
  default: "The Contacts report displays each instance of a Worker being nearby another Worker.",
};

export default {
  fetchData(api, filters, _route) {
    return api
      .query(`project/${filters.filters.project.id}/report/contacthistory`, {
        params: filters.getParams(),
      })
      .then((res) => {
        res.data = res.data.map((x) => {
          return {
            param: `${x.datetime}${x.asset.id}${x.contact_asset.id}`,
            Datetime: formatDatetime(x.datetime),
            Worker: x.asset.name,
            Contacted: x.contact_asset.name,
            "Est. Duration": getDuration(x.contact_event_duration),
          };
        });

        return res;
      });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Contacts",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Worker", "Contacted", "Datetime", "Est. Duration"],
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function () {
          // Do nothing
        },
        display: function () {
          return false;
        },
      },
    ],
    updateRows(report) {
      return report;
    },
  },
};
