import { addDays, parse } from "date-fns";
import { formatDatetime } from "@/common/date.filter";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English: "The Timekeeping report contains detailed Clock-in and Clock-out records.",
  Arabic: "يحتوي تقرير 'الجدول الزمني' على سجلات تفصيلية لوقت تسجيل الحضور وتسجيل الخروج",
  Default: "The Timekeeping report contains detailed Clock-in and Clock-out records.",
};

export default {
  fetchData(api, filters, route) {
    const { start, end } = filters.getParams();
    const endParsed = parse(end, "EEE MMM dd yyyy", new Date());
    const endPlus1Day = addDays(endParsed, 1).toDateString();

    return api.queryCursorPages(`project/${filters.filters.project.id}/log/staterange`, {
      params: {
        asset: route.query.id,
        start,
        end: endPlus1Day,
        types: ["clocked_in"],
      },
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Timekeeping Report",
    headers: ["param", "ID", "Name", "Clock-in", "Clock-out"],
    defaultSort: "Clock-in",
    defaultSortOrder: "asc",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function () {
          // Do nothing
        },
        display: function () {
          return false;
        },
      },
    ],
    updateRows(report) {
      return report.map((x) => ({
        // use `param` to avoid dup key error; `id` is reserved for key
        param: x.id,
        ID: x.employee_id,
        Name: x.name,
        "Clock-in": formatDatetime(x.start_datetime),
        "Clock-out": formatDatetime(x.end_datetime),
      }));
    },
  },
};
