import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Alignment Report displays the duration of time spent aligned with any assigned Activities for days when an Activity was assigned.",
  Arabic:
    "يعرض تقرير 'المطابقة' المدة الزمنية المستغرقة بأي مهمة معينة مقارنةً بالمدة المقررة للمهام في للأيام التي تم فيها تعيين المهام",
  default:
    "The Alignment Report displays the duration of time spent aligned with any assigned Activities for days when an Activity was assigned.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/timesheet`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn(report) {
      const filteredReport = report.filter(
        (x) => isWorkerOrEquipment(x.resourcetype_name) && x.arrival !== "NA"
      );

      return [
        {
          header: "Total Hours",
          val:
            filteredReport.length > 0
              ? filteredReport
                  .map((x) => (x.aligned_hours ? Number(x.aligned_hours) : 0))
                  .reduce((a, b) => a + b, 0)
              : "NA",
          suffix: "hrs",
          link: "",
          decimals: 1,
        },
        {
          header: "Avg Alignment %",
          val:
            filteredReport.length > 0
              ? Number(
                  filteredReport
                    .map((x) => (x.alignment ? Number(x.alignment * 100) : 0))
                    .reduce((a, b) => a + b, 0) / filteredReport.length
                )
              : "NA",
          suffix: "%",
          link: "",
          decimals: 0,
        },
      ];
    },
  },
  table: {
    title: "Alignment Report",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: [
      "param",
      "Date",
      "ID",
      "Name",
      "Resource",
      "Activity",
      "Hours Aligned",
      "% Alignment",
    ],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Activity: x.activity_name,
            "Hours Aligned": roundNum(x.aligned_hours, 1),
            "% Alignment": roundNum(x.alignment * 100, 0),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
