import isBoolean from "lodash/isBoolean";
import isString from "lodash/isString";

export default (value, shouldAllowWhitespace = false) => {
  if (!isBoolean(shouldAllowWhitespace)) {
    throw new Error("shouldAllowWhitespace argument is not valid");
  }

  return (
    isString(value) && (value.length === 0 || (!shouldAllowWhitespace && value.trim().length === 0))
  );
};
