export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "ProjectDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Project List",
  modalTitle: "Add Project",
  defaultSort: "Name",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "admin/project";
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: x.name,
        LatLng: `${x.lat.toFixed(5)}, ${x.lng.toFixed(5)}`,
        Timezone: x.timezone,
      };
    },
  },
  form: {
    title: "New Project",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data() {
      // Do nothing
    },
    submit(form, api, _userId) {
      api
        .post(`admin/project`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    model: {
      name: null,
      description: null,
      lat: null,
      lng: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Latitude",
          model: "lat",
          validator: ["required", "number"],
          min: -90,
          max: 90,
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Longitude",
          model: "lng",
          validator: ["required", "number"],
          min: -180,
          max: 180,
          required: true,
        },
        {
          type: "input",
          inputType: "textarea",
          label: "Description",
          model: "description",
          validator: "string",
          required: true,
        },
      ],
    },
  },
};
