import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { createPinia, PiniaVuePlugin } from "pinia";

import App from "./App.vue";
import DashboardPlugin from "./material-dashboard";
import router from "./router";

import { AuthPlugin } from "./common/auth";
import { APIServicePlugin } from "./common/api.service2";
import { SocketIOServicePlugin } from "./common/socketio.service";
import { AUTH_CONFIG, AUTH_METHOD } from "./common/config";

import filters from "./common/filter.service";
import notifyMethods from "./common/notify.service";

const pinia = createPinia();

const { audience, clientId, domain } = AUTH_CONFIG;
const authOptions =
  AUTH_METHOD === "auth0"
    ? {
        domain,
        clientId,
        audience,
      }
    : AUTH_CONFIG;

Vue.use(AuthPlugin, {
  ...authOptions,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(PiniaVuePlugin);

Vue.use(APIServicePlugin);
Vue.use(SocketIOServicePlugin);

Vue.use(filters);
Vue.use(notifyMethods);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  data: {},
  pinia,
  render: (h) => h(App),
}).$mount("#app");
