import Vue from "vue";
import { API_URL } from "@/common/config";

let apiService = new Vue({
  data: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // Vue.use(VueAxios, axios);
      Vue.axios.defaults.baseURL = API_URL;
    },

    query(resource, params) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.get(resource, params);
        })
        .catch((error) => {
          console.error(error);
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },
    async queryCursorPages(resource, options, timeout = 10000, maxRows = 10000) {
      const results = [];
      const queryOptions = { ...options };
      let complete = false;
      let timedOut = false;
      let apiCalls = 0;

      const token = await this.$auth.getTokenSilently();

      Vue.axios.defaults.baseURL = API_URL;
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      queryOptions.params = queryOptions.params ? { ...options.params } : {};

      // max time per attempt in case loop hangs
      setTimeout(() => {
        timedOut = true;
      }, timeout);

      // loop until complete
      while (!complete && !timedOut) {
        try {
          const res = await Vue.axios.get(resource, queryOptions);

          apiCalls += 1;
          results.push(...res.data.data);

          if (results.length >= maxRows) {
            complete = true;
          }

          if (res.data.pagination.hasNextPage) {
            queryOptions.params.cursor = res.data.pagination.cursor;
          } else {
            complete = true;
          }
        } catch (e) {
          throw new Error(e);
        }
      }

      return {
        pages: apiCalls,
        data: results,
        complete,
        timedOut,
      };
    },
    get(resource, slug = "") {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.get(`${resource}/${slug}`);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    post(resource, params) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.post(`${resource}`, params);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    patch(resource, params) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.patch(`${resource}`, params);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    publicPost(resource, params) {
      Vue.axios.defaults.baseURL = API_URL;

      return Vue.axios
        .post(`${resource}`, params)

        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    update(resource, slug, params) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.put(`${resource}/${slug}`, params);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    put(resource, params) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.put(`${resource}`, params);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },

    delete(resource) {
      return this.$auth
        .getTokenSilently()
        .then((token) => {
          Vue.axios.defaults.baseURL = API_URL;
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Vue.axios.delete(resource);
        })
        .catch((error) => {
          throw new Error(`[OTG] ApiService ${error}`);
        });
    },
  },
});

export default apiService;

export const APIServicePlugin = {
  install: function (Vue) {
    Vue.prototype.$api = apiService;
  },
};
