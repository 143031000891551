export const RESOURCETYPE_WORKER = "worker";
export const RESOURCETYPE_EQUIPMENT = "equipment";
export const RESOURCETYPE_TOOL = "tool";

export const isWorker = (resourceType) =>
  String(resourceType).toLowerCase() === RESOURCETYPE_WORKER;

export const isEquipment = (resourceType) =>
  String(resourceType).toLowerCase() === RESOURCETYPE_EQUIPMENT;

export const isTool = (resourceType) => String(resourceType).toLowerCase() === RESOURCETYPE_TOOL;

export const isWorkerOrEquipment = (resourceType) =>
  isWorker(resourceType) || isEquipment(resourceType);
