var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.baseComponent,{tag:"component",class:{ active: _vm.isActive || _vm.matchedRoute },attrs:{"tag":"li","to":{
    path: _vm.link.path ? _vm.link.path : '/',
    query: _vm.link.query ? _vm.link.query : {},
  }}},[(_vm.isMenu)?_c('a',{staticClass:"nav-link sidebar-menu-item",attrs:{"aria-expanded":!_vm.collapsed,"data-toggle":"collapse","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.collapseMenu.apply(null, arguments)}}},[_c('md-icon',[_vm._v(_vm._s(_vm.link.icon))]),_c('p',[_vm._v(" "+_vm._s(_vm.link.name)+" "),_c('b',{staticClass:"caret"})])],1):_vm._e(),_c('collapse-transition',[(_vm.$slots.default || _vm.isMenu)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}]},[_c('ul',{staticClass:"nav"},[_vm._t("default")],2)]):_vm._e()]),(_vm.children.length === 0 && !_vm.$slots.default && _vm.link.path)?_vm._t("title",function(){return [_c(_vm.elementType(_vm.link, false),{tag:"component",staticClass:"nav-link",class:{ active: _vm.link.active },attrs:{"href":_vm.link.path,"target":_vm.link.target,"to":{
        path: _vm.link.path,
        query: _vm.link.query ? _vm.link.query : {},
      }},nativeOn:{"click":function($event){return _vm.linkClick.apply(null, arguments)}}},[(_vm.addLink)?[_c('span',{staticClass:"sidebar-mini"},[_vm._v(_vm._s(_vm.linkPrefix))]),_c('span',{staticClass:"sidebar-normal"},[_vm._v(_vm._s(_vm.link.name))])]:[_c('md-icon',[_vm._v(_vm._s(_vm.link.icon))]),_c('p',[_vm._v(_vm._s(_vm.link.name))])]],2)]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }