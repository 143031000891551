export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "ResourceCodeDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Resource Codes",
  modalTitle: "Add Resource Code",
  resource: {
    url: function () {
      return "tenant/resourcecode";
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: x.name,
      };
    },
  },
  form: {
    title: "New Resource Code",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data() {
      // Do nothing
    },
    submit(form, api, _userId) {
      api
        .post(`admin/resourcecode`, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    model: {
      name: null,
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          validator: ["required", "string"],
          required: true,
        },
      ],
    },
  },
};
