import df from "@/common/date.filter";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Safety Rules Violations report displays any Safety Rule Violations by Workers and the date the violation occurred.",
  Arabic:
    "يعرض تقرير 'انتهاكات قواعد السلامة' أي انتهاكات لقواعد السلامة من قبل العمال وتاريخ الانتهاك",
  default:
    "The Safety Rules Violations report displays any Safety Rule Violations by Workers and the date the violation occurred.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/safetyalerts`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Safety Rules Violations",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Date", "Rule", "ID", "Name", "Resource", "Contractor"],
    actions: [
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: "Worker" },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report.map((x) => {
        return {
          // use `param` to avoid dup key error; `id` is reserved for key
          param: x.asset_id,
          Date: df(x.date),
          Rule: x.rule_name,
          ID: x.employee_id,
          Name: x.name,
          Resource: x.resource_name,
          Contractor: x.contractor_name,
        };
      });
    },
  },
};
