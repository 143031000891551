import df, { getDuration } from "@/common/date.filter";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The High Risk Contacts report displays Workers who spent a significant amount of time near each other in a single day.",
  Arabic:
    "يعرض تقرير 'الاختلاطات المهمة' العمال الذين أمضوا قدرًا كبيرًا من الوقت بالقرب من بعضهم البعض في يوم واحد",
  default:
    "The High Risk Contacts report displays Workers who spent a significant amount of time near each other in a single day.",
};

export default {
  fetchData(api, filters, _route) {
    return api
      .query(`project/${filters.filters.project.id}/report/contactdailyprofile`, {
        params: Object.assign({}, filters.getParams(), { minEvents: 5 }),
      })
      .then((res) => {
        res.data = res.data.map((x) => {
          return {
            param: `${x.date}${x.asset.id}${x.contact_asset.id}`,
            Date: df(x.date),
            Worker: x.asset.name,
            Contacted: x.contact_asset.name,
            "Est. Duration": getDuration(x.daily_duration),
          };
        });

        return res;
      });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "High Risk Contacts",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Worker", "Contacted", "Date", "Est. Duration"],
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function () {
          // Do nothing
        },
        display: function () {
          return false;
        },
      },
    ],
    updateRows(report) {
      return report;
    },
  },
};
