import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "This table contains the duration spent in each area from time of entry until time of next position outside the area, summed by date of entry. Time in Area >24 hours indicates that the device remained in the area overnight.",
  default:
    "This table contains the duration spent in each area from time of entry until time of next position outside the area, summed by date of entry. Time in Area >24 hours indicates that the device remained in the area overnight.",
  Arabic:
    "يحتوي هذا الجدول على المدة الزمنية في كل مساحة داخلية من وقت دخول المساحة  الداخلية حتى أول  اكتشاف خارج المساحة داخلية، مجمعة حسب تاريخ الدخول. تشير مدة زمنية في مساحة داخلية فوق 24 ساعة إلى أن الجهاز بقي في المساحة الداخلية بعد نهاية اليوم",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/inareahours`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Time in Area",
    headers: ["param", "Date", "ID", "Name", "Resource", "Area", "Hours"],
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "AssetMapBuilding",
            params: { asset_id: event.param, building_id: event.buildingId },
            query: { level: event.levelId },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Area: x.area_name,
            Hours: roundNum(x.hours_in_area + (x.active_hours_in_area || 0), 1),
            resourcetype_name: x.resourcetype_name,
            buildingId: x.building_id,
            levelId: x.level_id,
          };
        });
    },
  },
};
