import Multiselect from "vue-multiselect";
import VueApexCharts from "vue-apexcharts";
import DropDown from "./components/Dropdown.vue";
import MdSwitchField from "./components/_ottogee/Forms/MdSwitchField.vue";
import MultiselectField from "./components/_ottogee/Forms/MultiselectField.vue";
import TelephoneInput from "./components/_ottogee/Forms/TelephoneInput.vue";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("DropDown", DropDown);
    Vue.component("Multiselect", Multiselect);
    Vue.component("Apexchart", VueApexCharts);
    Vue.component("FieldMdSwitch", MdSwitchField);
    Vue.component("FieldMultiselect", MultiselectField);
    Vue.component("FieldTelInput", TelephoneInput);
  },
};

export default GlobalComponents;
