export default (values) => {
  let filteredValues = values.filter((x) => x !== null && x !== "NA");

  const len = filteredValues.length;

  if (len == 0) {
    return "NA";
  }

  const sum = filteredValues
    .filter((x) => x !== "NA")
    .map((x) => Number(x.split(":")[0]) * 60 + Number(x.split(":")[1]))
    .sort((a, b) => a - b)
    .reduce((a, v) => a + v, 0);

  const avg = Math.round(sum / len);

  const hours = Math.floor(avg / 60);
  const mins = avg % 60;

  const formattedHour = ("0" + hours).slice(-2);
  const formattedMin = ("0" + mins).slice(-2);
  const formattedTime = formattedHour + ":" + formattedMin;

  return formattedTime;
};
