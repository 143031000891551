/**
 * customSort() sorts an array according to the specified sort order (ascending or descending).
 * If the array contains one or more objects, a sortBy value that corresponds to an object key must be provided.
 * Null placeholders (e.g., "NA") will always be sorted after numbers and/or other strings.
 * Null values will always be sorted last.
 */

import compareString from "./compareString";

const customCompareString = (a, b, sortAscending) => {
  const sortingMultiplier = sortAscending ? 1 : -1;

  return compareString(a, b) * sortingMultiplier;
};

const isNull = (x) => x === null;

const isNullPlaceholder = (x) => {
  const nullPlaceholders = ["NA"];

  return nullPlaceholders.includes(x);
};

const isObject = (x) => typeof x === "object" && !Array.isArray(x) && x !== null;

export default (arr, sortAscending = true, sortBy = "") => {
  // Check if arr is valid
  const isValidArr = Array.isArray(arr);

  if (!isValidArr) {
    return [];
  }

  // Check if sortAscending is valid
  const isValidSortAscending = typeof sortAscending === "boolean";

  if (!isValidSortAscending) {
    return [...arr];
  }

  // Check if sortBy type is valid
  const isValidSortByType = typeof sortBy === "string";

  if (!isValidSortByType) {
    return [...arr];
  }

  // Check if arr contains objects
  const arrContainsObjects = arr.length > 0 && arr.every((x) => isObject(x));

  if (arrContainsObjects) {
    // Check if sortBy value is valid
    const { hasOwnProperty } = Object.prototype;
    const isValidSortByValue =
      sortBy.length > 0 && arr.every((x) => hasOwnProperty.call(x, sortBy));

    if (!isValidSortByValue) {
      return [...arr];
    }
  }

  // Sort arr
  const sortedArr = [...arr].sort((a, b) => {
    // Get element values
    const [c, d] = [a, b].map((x) => (arrContainsObjects ? x[sortBy] : x));

    if (isNull(c) && isNull(d)) {
      // Sort equally
      return 0;
    }

    if (isNullPlaceholder(c) && isNullPlaceholder(d)) {
      // Sort alphabetically in specified order
      return customCompareString(c, d, sortAscending);
    }

    if (isNull(c) && isNullPlaceholder(d)) {
      // Sort null placeholder (d) before null value (c)
      return 1;
    }

    if (isNullPlaceholder(c) && isNull(d)) {
      // Sort null placeholder (c) before null value (d)
      return -1;
    }

    if (isNull(c) || isNullPlaceholder(c)) {
      // Sort null value/placeholder (c) last
      return 1;
    }

    if (isNull(d) || isNullPlaceholder(d)) {
      // Sort null value/placeholder (d) last
      return -1;
    }

    const areNumbers = typeof c === "number" && typeof d === "number";

    if (areNumbers) {
      // Compare numbers
      return sortAscending ? c - d : d - c;
    }

    // Compare strings
    return customCompareString(c, d, sortAscending);
  });

  return sortedArr;
};
