import api from "@/common/api.service";

export default {
  actions: [
    {
      name: "Install Scanner",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "Scanner Install",
          params: { id: event.id },
        });
      },
      display: function (item) {
        return !item["Assigned"];
      },
    },
    {
      name: "Uninstall",
      icon: "location_off",
      handler: function (event) {
        api.post(`admin/hardware/${event.id}/unassign`).then(() => {
          event["Install Date"] = null;
          event["Resource"] = null;
          event["Assigned"] = null;
          event["Level"] = null;
          event["Building"] = null;
        });
      },
      display: function (item) {
        return item["Assigned"];
      },
    },
    /*{
      name: "Detail",
      icon: "info",
      handler: function(event, router) {
        router.push({
          name: "HardwareDetail",
          params: { id: event.id }
        });
      },
      display: function(item) {
        return true;
      }
    }*/
  ],
  title: "Scanners",
  modalTitle: "Add Hardware",
  resource: {
    url: function () {
      return "admin/hardware?type=scanner";
    },
    transform: function (x) {
      return {
        id: x.id,
        Serial: x.serial,
        Assigned: x.asset || x.point,
        "Assignment Date": x.assigned ? x.assigned.substring(0, 10) : null,
      };
    },
  },
};
