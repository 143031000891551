import BaseJoi from "joi";
import JoiPhoneNumberExtensions from "joi-phone-number";
import JoiDateExtension from "@joi/date";

const BEACON_MODELS = ["PSD", "TRK230", "TRK230S", "Beacon", "IPB"];

export const ALL_DEVICE_MODELS = ["PSD", "IPB", "Beacon", "V5", "TRK230", "TRK230S"];
export const BULK_CONFIG_MODELS = ["PSD", "TRK230", "TRK230S"];
export const BULK_DEPLOY_MODELS = ["PSD", "IPB", "Beacon", "V5", "TRK230", "TRK230S"];
export const SIM_ASSIGN_MODELS = ["PSD", "IPB", "TRK230", "TRK230S"];
export const DETAIL_PAGE_MODELS = ["PSD", "IPB", "V5", "TRK230", "TRK230S"];
export const DEVICE_API_CALL_ROW_LIMIT = 25000;

export const DUPLICATE_LEVEL_UPLOAD_DB_ERROR_MESSAGE =
  "Failed to add level - Level with same name previously created";

export const UPLOAD_ERROR_MESSAGES_TO_NOTIFY = {
  fileUpload: [DUPLICATE_LEVEL_UPLOAD_DB_ERROR_MESSAGE],
  formUpload: [],
};

// Requires lib/index.js from joi-phone-number@4.0 but rest of package from joi-phone-number@3.1.0
const joi = BaseJoi.extend(JoiPhoneNumberExtensions).extend(JoiDateExtension);

const bulkDevice = joi
  .string()
  .trim()
  .min(8)
  .max(32)
  .uppercase()
  .messages({
    "string.min": "Device serial should be 8-32 characters",
    "string.max": "Device serial should be 8-32 characters",
    "string.trim": "Device serial should not contain any spaces",
    "string.uppercase": "Device serial should be uppercase",
    "any.required": `Device is a required field`,
  })
  .required();

// allows specification where device serial is used to generate advertisement
const bulkDeviceBeacon = joi
  .string()
  .trim()
  .min(8)
  .max(8)
  .hex()
  .uppercase()
  .messages({
    "string.min": "Device serial for models PSD, TRK230, Beacon, and IPB should be 8 characters",
    "string.max": "Device serial for models PSD, TRK230, Beacon, and IPB should be 8 characters",
    "string.trim": "Device serial should not contain any spaces",
    "string.uppercase": "Device serial should be uppercase",
    "string.hex":
      "Device serials for models PSD, TRK230, Beacon, and IPB must only contain hexadecimal characters.",
    "any.required": `Device is a required field`,
  })
  .required();

const bulkIccid = joi
  .string()
  .min(18)
  .max(22)
  .trim()
  .regex(/^\d+$/)
  .messages({
    "string.min": "ICCID should be 18-22 characters",
    "string.max": "ICCID should be 18-22 characters",
    "string.trim": "ICCID should not contain any spaces",
    "any.required": `ICCID is a required field`,
    "string.pattern.base": "ICCID should contain only numeric characters",
  })
  .required();

export default {
  utils: {
    validatePhone: (phoneNumber) =>
      joi
        .string()
        .trim()
        .required()
        .phoneNumber({ format: "e164", strict: true })
        .validate(phoneNumber, {
          abortEarly: false,
          convert: false,
        }),
  },
  BulkRegisterDevice: joi.object().keys({
    device: joi.when("model", {
      is: joi.valid(...BEACON_MODELS),
      then: bulkDeviceBeacon,
      otherwise: bulkDevice,
    }),
    mac: joi
      .string()
      .lowercase()
      .hex()
      .trim()
      .min(12)
      .max(12)
      .lowercase()
      .messages({
        "string.min": "Mac should be 12 characters",
        "string.max": "Mac should be 12 characters",
        "string.trim": "Mac should not contain any spaces",
        "any.required": `Mac is a required field`,
        "string.lowercase": "Mac should be lowercase",
        "string.hex":
          "Mac must only contain hexadecimal characters. Do not include ':' separators.",
      })
      .required(),
    model: joi
      .string()
      .trim()
      .messages({
        "string.trim": "Model should not contain any spaces",
        "any.required": `Model is a required field`,
      })
      .required(),
    version: joi.string().trim().allow(null).messages({
      "string.trim": "Version should not contain any spaces",
    }),
  }),
  BulkRegisterNfcTag: joi.object().keys({
    uid: joi
      .string()
      .trim()
      .min(8)
      .max(20)
      .hex()
      .uppercase()
      .messages({
        "string.min": "NFC Tag UIDs should be 8-20 characters",
        "string.max": "NFC Tag UIDs should be 8-20 characters",
        "string.trim": "NFC Tag UIDs should not contain any spaces",
        "string.uppercase": "NFC Tag UIDs should be uppercase",
        "string.hex": "NFC Tag UIDs hexadecimal characters.",
        "any.required": `UID is a required field`,
      })
      .required(),
  }),
  BulkRegisterSim: joi.object().keys({
    iccid: bulkIccid,
    provider: joi
      .string()
      .trim()
      .messages({
        "string.trim": "Provider should not contain any spaces",
        "any.required": `Provider is a required field`,
      })
      .required(),
  }),
  BulkAssignSim: joi.object().keys({
    iccid: bulkIccid,
    device: bulkDevice,
  }),
  BulkConfigDevice: joi.object().keys({
    device: bulkDevice,
    config: joi
      .string()
      .messages({
        "any.required": `Config is a required field`,
      })
      .required(),
  }),
  BulkDeployDevice: joi.object().keys({
    device: bulkDevice,
    tenant: joi
      .string()
      .messages({
        "any.required": `Tenant is a required field`,
      })
      .required(),
  }),
};
