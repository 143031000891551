import { useTenantResourceStore } from "@/stores/tenantResource";

import createIconFields from "./createIconFields";

const refreshTenantResourceStore = (api, tenantResourceStore) => {
  const { transformResourceData } = tenantResourceStore;

  tenantResourceStore.$reset();

  api
    .get("tenant/resource")
    .then(transformResourceData)
    .then((resources) => {
      tenantResourceStore.$patch({
        resources,
        hasLoadedResources: true,
      });
    });
};

const initialModel = {
  name: null,
  type: null,
  icon: null,
  description: null,
};

export default {
  actions: [
    {
      name: "Edit",
      icon: "edit",
      handler: function (event, router) {
        router.push({
          name: "Admin Resource Edit",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Resource List",
  modalTitle: "Add Resource",
  defaultSort: "Name",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "tenant/resource";
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: x.name,
        Type: x.type,
        Description: x.description,
      };
    },
  },
  speedDial: [
    {
      to: "/admin/bulk/resource/register",
      icon: "group_work",
      label: "Bulk Registration",
    },
  ],
  form: {
    title: "New Resource",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(form, api) {
      const { fields } = form.form.schema;
      const typeField = fields.find((x) => x.model === "type");

      api.get("tenant/resourcetype").then(({ data }) => {
        typeField.values = data;
      });

      api.get("tenant/resourceicon").then(({ data }) => {
        const icons = data;
        const currentIconFieldCount = fields.filter((x) => x.model === "icon").length;
        const defaultIconFieldIndex = fields.findIndex((x) => x.model === "icon");
        const iconFields = createIconFields(icons, typeField);

        // Remove any previously created icon fields
        fields.splice(defaultIconFieldIndex, currentIconFieldCount - 1);

        // Add newly created icon fields
        iconFields.forEach((iconField, index) => {
          fields.splice(defaultIconFieldIndex + index, 0, iconField);
        });
      });
    },
    handleModelUpdated(form, newVal, schema) {
      if (schema === "type") {
        const { fields } = form.schema;
        const typeField = fields.find((x) => x.model === "type");
        const typeName = typeField.values.find((x) => x.id === newVal).name;
        const iconField = fields.find(
          (x) => x.model === "icon" && x.customProps.iconType === typeName
        );

        this.model.icon = iconField?.selectOptions?.value ?? initialModel.icon;
      }
    },
    submit(form, api, _userId) {
      const { icon } = this.model.icon;
      const toSubmit = { ...this.model, icon };

      api
        .post(`admin/resource`, toSubmit)
        .then(() => {
          const tenantResourceStore = useTenantResourceStore();

          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
          refreshTenantResourceStore(api, tenantResourceStore);
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      this.model = { ...initialModel };
    },
    model: { ...initialModel },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          required: true,
          validator: ["required", "string"],
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
        },
        {
          type: "select",
          label: "Type",
          model: "type",
          required: true,
          validator: "required",
          selectOptions: {
            noneSelectedText: "Select Resource Type...",
          },
          values: [],
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
        },
        {
          type: "multiselect",
          label: "Icon",
          model: "icon",
          placeholder: "Select Icon",
          required: true,
          validator: ["required", (_value, _field, _model) => ["Please select a type"]],
          selectOptions: {
            value: null,
            trackBy: "icon",
            label: "icon",
            searchable: false,
          },
          values: [],
          visible: (model) => model.type === null,
          disabled: true,
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
          customProps: {
            iconType: null,
            isIconMultiselect: true,
            slots: {
              option: true,
              singleLabel: true,
            },
          },
        },
        {
          type: "input",
          inputType: "textarea",
          label: "Description",
          model: "description",
          validator: "string",
          required: true,
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
        },
      ],
    },
  },
};
