<template>
  <div
    class="sidebar"
    :data-background-color="backgroundColor"
    :data-color="activeColor"
  >
    <div class="logo">
      <a
        class="simple-text logo-mini"
        :href="logoUrl"
        target="_blank"
      >
        <div class="logo-img">
          <img :src="logo" />
        </div>
      </a>
      <a
        class="simple-text logo-normal"
        :href="logoUrl"
        target="_blank"
      >
        <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
        <template v-else>{{ title }}</template>
      </a>
      <div class="navbar-minimize">
        <md-button
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent"
          @click="minimizeSidebar"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </md-button>
      </div>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper"
    >
      <slot />
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, subIndex) in link.children"
              :key="subLink.name + subIndex"
              :link="subLink"
            />
          </sidebar-item>
        </slot>
      </md-list>
      <slot name="sidebar-footer" />
    </div>
  </div>
</template>

<script>
import { BRAND_CONFIG } from "@/common/config";

export default {
  name: "Sidebar",
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  props: {
    title: {
      type: String,
      default: () => BRAND_CONFIG.name,
    },
    rtlTitle: {
      type: String,
      default: "توقيت الإبداعية",
    },
    activeColor: {
      type: String,
      default: "primary",
      validator: (value) => {
        let acceptedValues = [
          "",
          "info",
          "blue",
          "primary",
          "azure",
          "green",
          "orange",
          "danger",
          "rose",
        ];

        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["", "black", "white", "red"];

        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: BRAND_CONFIG.logo.sidebar,
    },
    logoUrl: {
      type: String,
      default: BRAND_CONFIG.url,
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
};
</script>

<style lang="scss">
@media screen and (min-width: $screen-md-min) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
