export default (value) => {
  const isNull = value === null;
  const isUndefined = value === undefined;
  const isEmptyString = value === "";
  const isString = typeof value === "string";

  if (isNull || isEmptyString || isUndefined || !isString) {
    return ["Please enter a valid value"];
  }

  if (value.includes(",")) {
    return ["Field cannot contain commas"];
  }

  return [];
};
