import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "@/common/auth/authGuard";
import { BRAND_CONFIG } from "@/common/config";
import { adminRoutes } from "./admin";
import { globalAdminRoutes } from "./globalAdmin";
import { helpRoutes } from "./help";
import { projectRoutes } from "./project";
import { userRoutes } from "./user";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    ...adminRoutes,
    ...globalAdminRoutes,
    ...helpRoutes,
    ...projectRoutes,
    ...userRoutes,
    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/User/Home.vue"),
      meta: {},
    },
    {
      path: "/signup",
      name: "Signup",
      component: () => import("@/pages/User/Signup.vue"),
      meta: {
        // Use authguard to validate token with backend before allowing password reset
        validateToken: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/User/Login.vue"),
      meta: {
        public: true,
      },
    },
    {
      path: "/reset",
      name: "ResetPassword",
      component: () => import("@/pages/User/ResetPassword.vue"),
      meta: {
        // Use authguard to validate token with backend before allowing password reset
        validateToken: true,
      },
    },
    {
      path: "/callback",
      name: "Callback",
      component: () => import("@/pages/User/Callback.vue"),
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import("@/pages/User/Logout.vue"),
    },
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: (to, _from, _savedPosition) => {
    const mainPanelWithPerfectScrollbar = document.querySelector(".main-panel.ps.ps--active-y");

    if (mainPanelWithPerfectScrollbar) {
      mainPanelWithPerfectScrollbar.scrollTop = 0;

      return {};
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
  linkExactActiveClass: "nav-item active",
});

router.beforeEach(authGuard);

router.afterEach((to, _from, _next) => {
  if (to.meta === undefined || to.meta.title === undefined) {
    document.title = `${BRAND_CONFIG.title} - ${to.name}`;
  } else {
    document.title = `${BRAND_CONFIG.title} - ${to.meta.title}`;
  }
});

export default router;
