import { startTimeValidation, endTimeValidation } from "@/common/util/formValidations";

const initialModel = {
  name: null,
  start: null,
  end: null,
  span: null,
  duration: null,
  zones: [],
  areas: [],
  assets: [],
};

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "Project Safety Detail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Safety Rules",
  modalTitle: "Add Rule",
  resource: {
    url: function (id) {
      return `project/${id.project.id}/safety/rule`;
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: `${x.name}`,
        Hours: `${x.limit}`,
        "Start Time": x.startTime,
        "End Time": x.endTime,
        Zones: x.zones,
        Areas: x.areas,
        Exceptions: x.exceptions,
      };
    },
  },
  form: {
    title: "New Rule",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(form, api) {
      api
        .query(`project/${form.$filters.filters.project.id}/location/zone`, {
          params: { for: "form" },
        })
        .then((res) => {
          form.form.schema.fields.find((x) => x.model === "zones").values = function () {
            return res.data.map(({ id, name }) => ({
              value: id,
              name,
            }));
          };
        });

      api
        .query(`project/${form.$filters.filters.project.id}/location/area`, {
          params: { for: "form" },
        })
        .then((res) => {
          form.form.schema.fields.find((x) => x.model === "areas").values = function () {
            return res.data.map(({ id, name }) => ({
              value: id,
              name,
            }));
          };
        });

      api
        .query(`project/${form.$filters.filters.project.id}/asset`, {
          params: {
            type: "Worker",
          },
        })
        .then((res) => {
          form.form.schema.fields.find((x) => x.model === "assets").values = function () {
            return res.data.map(({ id, name }) => ({
              value: id,
              name,
            }));
          };
        });
    },
    submit(form, api, _userId) {
      const url = `project/${form.$filters.filters.project.id}/safety/rule`;
      const toSubmit = {
        name: this.model.name,
        start: this.model.start,
        end: this.model.end,
        span: this.model.span,
        duration: this.model.duration,
        zones: this.model.zones.map((x) => x.value),
        areas: this.model.areas.map((x) => x.value),
        assets: this.model.assets.map((x) => x.value),
      };

      api
        .post(url, toSubmit)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      this.model = { ...initialModel };
    },
    model: { ...initialModel },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Rule Name",
          model: "name",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "input",
          inputType: "time",
          label: "Start Time",
          model: "start",
          validator: ["required", startTimeValidation],
          required: true,
        },
        {
          type: "input",
          inputType: "time",
          label: "End Time",
          model: "end",
          validator: ["required", endTimeValidation],
          required: true,
        },
        {
          type: "input",
          inputType: "number",
          label: "Hour Limit",
          model: "duration",
          required: true,
          min: 0,
          max: 23,
          validator: ["required", "number"],
        },
        {
          type: "multiselect",
          label: "Zones",
          model: "zones",
          placeholder: "Select Zones",
          validator: ["array"],
          selectOptions: {
            multiple: true,
            trackBy: "value",
            label: "name",
            searchable: true,
            closeOnSelect: false,
          },
          values: [],
        },
        {
          type: "multiselect",
          label: "Areas",
          model: "areas",
          placeholder: "Select Areas",
          validator: ["array"],
          selectOptions: {
            multiple: true,
            trackBy: "value",
            label: "name",
            searchable: true,
            closeOnSelect: false,
          },
          values: [],
        },
        {
          type: "multiselect",
          label: "Exceptions",
          model: "assets",
          placeholder: "Select Exceptions",
          validator: ["array"],
          selectOptions: {
            multiple: true,
            trackBy: "value",
            label: "name",
            searchable: true,
            closeOnSelect: false,
          },
          values: [],
        },
      ],
    },
  },
};
