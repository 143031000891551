import df, { formatDatetime } from "@/common/date.filter";
import meanTime from "@/common/util/meanTime";
import roundNum from "@/common/util/roundNum";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Attendance report displays the first and last time a position was reported in any Zone each day and the total duration of time in any Zone each day.",
  Arabic:
    "يعرض تقرير 'الحضور'  أول وأخر اكتشاف في أي سياج جيوغرافي كل يوم والمدة الإجمالية للوقت في أي سياج جيوغرافي لكل يوم.",
  default:
    "The Attendance report displays the first and last time a position was reported in any Zone each day and the total duration of time in any Zone each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/timeOnSite`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: true,
    computeFn(report) {
      // remove absent workers and non-workers from top line stats
      const filteredReport = report.filter(
        (x) => isWorkerOrEquipment(x.resourcetype_name) && x.arrival && x.arrival !== "NA"
      );

      return [
        {
          header: "Total Hours on Site",
          val:
            filteredReport.length > 0
              ? filteredReport
                  .map((x) => (x.hours_on_site ? Number(x.hours_on_site) : 0))
                  .reduce((a, b) => a + b, 0)
              : "NA",
          link: "",
          decimals: 1,
        },
        {
          header: "Total Days on Site",
          val: filteredReport.length > 0 ? filteredReport.length : "NA",
          link: "",
          decimals: 0,
        },
        {
          header: "Avg Arrival",
          val: filteredReport.length > 0 ? meanTime(filteredReport.map((x) => x.arrival)) : "NA",
          link: "",
          decimals: 0,
        },
        {
          header: "Avg Departure",
          val: filteredReport.length > 0 ? meanTime(filteredReport.map((x) => x.departure)) : "NA",
          link: "",
          decimals: 0,
        },
      ];
    },
  },
  table: {
    title: "Attendance",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Date", "ID", "Name", "Resource", "Arrival", "Departure", "Hours on Site"],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "Detail2",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Arrival: x.arrival ? formatDatetime(x.arrival) : "NA",
            Departure: x.departure ? formatDatetime(x.departure) : "NA",
            "Hours on Site": roundNum(x.hours_on_site, 1),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
