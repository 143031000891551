export const helpRoutes = [
  {
    path: "/help",
    component: () => import("@/pages/Help/Layout/HelpLayout.vue"),
    children: [
      {
        path: "changelog",
        name: "Help Changelog",
        component: () => import("@/pages/Help/Pages/Changelog/Changelog.vue"),
        meta: {
          title: "Changelog",
        },
      },
      {
        path: "documentation",
        name: "Help Documentation",
        component: () => import("@/pages/Help/Pages/Documentation/Documentation.vue"),
        meta: {
          title: "Documentation",
        },
      },
      {
        path: "videos",
        name: "Help Videos",
        component: () => import("@/pages/Help/Pages/Video/Video.vue"),
        meta: {
          title: "Videos",
        },
      },
    ],
  },
];
