import Vue from "vue";

let filterService = new Vue({
  data: {
    filters: {
      project: {},
      selectedAssets: [],
      selectedContractors: [],
      selectedRosters: [],
      selectedZones: [],
      selectedActivities: [],
      selectedCostCodes: [],
      selectedResources: [],
      time: [],
      count: 0,
    },
    // TODO: Determine whether to replace or remove deprecated `navigator.platform`
    isIOS:
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream,
  },
  methods: {
    setProject(project) {
      this.project = project;
    },
    setFilters(filters) {
      Object.assign(this.filters, filters);
    },
    clearFilters() {
      this.filters.time = [];
      this.filters = {
        project: {},
        selectedAssets: [],
        selectedContractors: [],
        selectedRosters: [],
        selectedZones: [],
        selectedActivities: [],
        selectedCostCodes: [],
        selectedResources: [],
        time: [],
        count: 0,
      };
    },
    getParams() {
      return {
        start: this.filters.time[0] ? this.filters.time[0] : new Date(Date.now()).toDateString(),
        end: this.filters.time[1] ? this.filters.time[1] : new Date(Date.now()).toDateString(),
        contractor: this.filters.selectedContractors || [],
        roster: this.filters.selectedRosters || [],
        asset: this.filters.selectedAssets || [],
        activities: this.filters.selectedActivities || [],
        zones: this.filters.selectedZones || [],
        costcode: this.filters.selectedCostCodes || [],
        resources: this.filters.selectedResources || [],
      };
    },
  },
});

export default {
  install: function (Vue) {
    Vue.prototype.$filters = filterService;
  },
};
