import customSort from "@/common/util/customSort";
import markerIcons from "@/common/reference-data/markerIcons";
import validateIcon from "./validateIcon";

const getIconsByType = (icons) =>
  icons.reduce((x, y) => {
    x[y.type] = [...(x[y.type] || []), y];

    return x;
  }, {});

export default (icons, typeField) => {
  const sortedIcons = customSort(icons, true, "description");
  const iconsByType = getIconsByType(sortedIcons);
  const iconFields = Object.entries(iconsByType).map(([iconType, iconValues]) => {
    const defaultIconName = markerIcons.get(iconType)?.icon;
    const defaultIcon = iconValues.find((x) => x.icon === defaultIconName);

    return {
      type: "multiselect",
      label: "Icon",
      model: "icon",
      placeholder: "Select Icon",
      required: true,
      validator: ["required", (value, _field, _model) => validateIcon(value)],
      selectOptions: {
        value: defaultIcon,
        trackBy: "icon",
        label: "icon",
        searchable: false,
      },
      values: iconValues,
      visible: (model) => {
        const typeId = model.type;

        if (typeId === null) {
          return false;
        }

        const typeName = typeField.values.find((x) => x.id === typeId).name;

        return iconType === typeName;
      },
      styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
      customProps: {
        iconType,
        isIconMultiselect: true,
        slots: {
          option: true,
          singleLabel: true,
        },
      },
    };
  });

  return iconFields;
};
