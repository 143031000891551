import isString from "lodash/isString";
import api from "@/common/api.service";
import { FEATURES } from "@/common/config";
import compareString from "@/common/util/compareString";
import isEmptyString from "@/common/util/isEmptyString";
import validatePhoneNumber from "@/common/util/validatePhoneNumber";
import validateStringHasNoCommas from "@/common/util/validateStringHasNoCommas";

const type = "Worker";
const initialModel = {
  asset_name: null,
  resource: null,
  contractor: null,
  hourly_rate: null,
  overtime_rate: null,
  employee_id: null,
  manager: null,
  type,
  phone: null,
};

/**
 * These variables are used to enable/disable initial validation of all form fields.
 * Validation is disabled until the phone input field is ready.
 * When the vue-tel-input component initializes, it (1) sets the field value and (2) formats it.
 */
const initialValidationSettings = {
  formValidationIsEnabled: !FEATURES.phoneNumbers,
  phoneChangeCount: 0,
};
let { formValidationIsEnabled, phoneChangeCount } = initialValidationSettings;
const minCountFormEnable = 2;

const speedDialOptions = [
  {
    to: "/admin/bulk/asset/register?type=Worker",
    icon: "people",
    label: "Bulk Registration",
  },
  {
    to: "/admin/bulk/asset/assigndevice",
    icon: "devices_other",
    label: "Bulk Assign Device",
  },
  {
    to: "/admin/bulk/asset/assignproject",
    icon: "assignment",
    label: "Bulk Assign Project",
  },
];

if (FEATURES.nfcTags) {
  speedDialOptions.push({
    to: "/admin/bulk/asset/assignnfctag",
    icon: "nfc",
    label: "Bulk Assign NFC Tag",
  });
}

export default {
  actions: [
    {
      name: "Assign Asset",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "Admin Asset Assign Project",
          params: { id: event.id },
          query: { type },
        });
      },
      display: function (item) {
        return !item.Project;
      },
    },
    {
      name: "Unassign",
      icon: "location_off",
      handler: function (event, _router) {
        api.post(`admin/asset/${event.id}/unassignproject`).then(() => {
          event.Project = null;
          event.Roster = null;
        });
      },
      display: function (item) {
        return item.Project;
      },
    },
    {
      name: "Update",
      icon: "edit",
      handler: function (event, router) {
        router.push({
          name: "Update Asset",
          params: { id: event.id },
          query: { type },
        });
      },
      display: function (_item) {
        return true;
      },
    },
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "Admin Asset Detail",
          params: { id: event.id },
          query: { type },
        });
      },
      display: function (_item) {
        return true;
      },
    },
  ],
  title: "Worker List",
  modalTitle: "Add Worker",
  modalUpdateTitle: "Update Worker",
  defaultSort: "ID",
  defaultSortOrder: "asc",
  resource: {
    url: function (_id) {
      return "admin/asset?type=Worker";
    },
    transform: function (x) {
      return {
        id: x.id,
        ID: x.employee_id,
        Name: `${x.name}`,
        Resource: x.resource,
        Contractor: x.contractor,
        Project: x.project,
      };
    },
  },
  speedDial: speedDialOptions,
  form: {
    title: "New Asset",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    getFormValidationIsEnabled() {
      return formValidationIsEnabled;
    },
    async data({ form }, api) {
      const { id: assetId, schema } = form;
      const { fields } = schema;

      if (isString(assetId) && !isEmptyString(assetId)) {
        await api.get(`admin/asset/${assetId}`).then(({ data }) => {
          const { wage } = data;

          // NOTE: Mutation
          form.model = {
            asset_name: data.name,
            resource: data.resource_id,
            contractor: data.contractor_id,
            hourly_rate: Number(wage),
            overtime_rate: wage * (data.multiplier || 1),
            employee_id: data.employee_id,
            manager: data.manager_id,
            type: data.resource_type,
            phone: data.phone,
          };
        });
      }

      api.get("admin/resource").then(({ data }) => {
        // NOTE: Mutation
        fields.find(({ model }) => model === "resource").values = function () {
          return data
            .filter((x) => x.type === type)
            .map(({ id, name }) => ({ id, name }))
            .sort((a, b) => compareString(a.name, b.name));
        };
      });

      api.get("admin/contractor").then(({ data }) => {
        // NOTE: Mutation
        fields.find(({ model }) => model === "contractor").values = function () {
          return data
            .map(({ id, name }) => ({ id, name }))
            .sort((a, b) => compareString(a.name, b.name));
        };
      });
    },
    submit(form, api, userId) {
      const { $notifyMethods } = form;
      const { model } = this;
      const { phone } = model;
      const toSubmit = {
        ...model,
        manager: userId,
        phone: isEmptyString(phone) ? null : phone,
      };

      api
        .post("admin/asset", toSubmit)
        .then(({ headers: { location } }) => {
          const assetId = location.match(/(\w+-){4}\w+$/g)[0];

          form.$emit("submitSuccess");
          $notifyMethods.formSubmitSuccess();
          form.getRows();

          // NOTE: Mutation
          form.classicModal = false;

          return form.$router.push({
            name: "Admin Asset Detail",
            params: { id: assetId },
            query: { type },
          });
        })
        .catch((err) => {
          console.error(err);
          $notifyMethods.formSubmitFail();
        });
    },
    update(form, api, userId) {
      const { $notifyMethods, id: assetId } = form;
      const { model } = this;
      const { phone } = model;
      const toSubmit = {
        ...model,
        manager: userId,
        phone: isEmptyString(phone) ? null : phone,
      };

      api
        .put(`admin/asset/${assetId}`, toSubmit)
        .then(() => {
          form.$emit("submitSuccess");
          $notifyMethods.formSubmitSuccess();

          return form.$router.push({
            name: "Admin Asset Detail",
            params: { id: assetId },
            query: { type },
          });
        })
        .catch((err) => {
          console.error(err);
          $notifyMethods.formSubmitFail();
        });
    },
    disableInputs(form, shouldDisable = true) {
      const { fields } = form.schema;
      const inputs = ["employee_id"];
      const fieldKeys = Object.keys(fields);

      fieldKeys.forEach((fieldKey) => {
        const field = fields[fieldKey];

        // NOTE: Mutation
        fields[fieldKey] = {
          ...field,
          disabled: shouldDisable && inputs.includes(field.model),
        };
      });
    },
    resetModel() {
      ({ formValidationIsEnabled, phoneChangeCount } = initialValidationSettings);
      this.model = { ...initialModel };
      this.id = null;
    },
    closeRoute(router) {
      router.push({
        name: "Admin Assets",
        query: { type },
      });
    },
    model: { ...initialModel },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Employee ID",
          model: "employee_id",
          required: true,
          validator: [
            "required",
            "string",
            function (value, _field, _model) {
              return validateStringHasNoCommas(value);
            },
          ],
        },
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "asset_name",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "tel-input",
          label: "Phone Number",
          model: "phone",
          validator: [
            function (value, _field, _model) {
              return validatePhoneNumber(value);
            },
          ],
          visible: FEATURES.phoneNumbers,
          validateDebounceTime: 1,
          onChanged: function (_model, _newVal, _oldVal, _field) {
            phoneChangeCount++;

            if (phoneChangeCount === minCountFormEnable) {
              formValidationIsEnabled = true;
            }
          },
        },
        {
          type: "select",
          label: "Resource",
          model: "resource",
          selectOptions: {
            noneSelectedText: "Select Resource...",
          },
          values: [],
          required: true,
          validator: "required",
        },
        {
          type: "select",
          label: "Contractor",
          model: "contractor",
          selectOptions: {
            noneSelectedText: "Select Contractor...",
          },
          values: [],
          required: true,
          validator: "required",
        },
        {
          type: "input",
          inputType: "number",
          label: "Hourly Rate",
          model: "hourly_rate",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
      ],
    },
  },
};
