import customSort from "@/common/util/customSort";
import createCustomHeaders from "./createCustomHeaders";

const { customHeaders, customHeaderFields, customHeaderKeys, initialCustomHeader } =
  createCustomHeaders(5);

const initialModel = {
  name: null,
  url: null,
  eventTypes: [],
  customHeaders,
};

export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "Admin Webhook Detail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Webhook List",
  modalTitle: "Add Webhook",
  defaultSort: "Name",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "admin/webhook";
    },
    transform: function (x) {
      return {
        id: x.id,
        Name: x.name,
        URL: x.url,
      };
    },
  },
  form: {
    title: "New Webhook",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    data(form, api) {
      api
        .query("tenant/eventtype", {
          params: {
            webhookAllowed: true,
          },
        })
        .then((res) => {
          const hasData = Array.isArray(res.data) && res.data.length > 0;

          if (!hasData) {
            return;
          }

          const eventTypesField = form.form.schema.fields.find(
            (field) => field.model === "eventTypes"
          );
          const hasEventTypesField = eventTypesField !== undefined;

          if (!hasEventTypesField) {
            return;
          }

          const sortedEventTypes = customSort(res.data.map(({ name }) => name));

          eventTypesField.values = () => sortedEventTypes;
        })
        .catch((err) => console.error(err));
    },
    submit(form, api, _userId) {
      const toSubmit = {
        ...this.model,
        customHeaders: Object.entries(this.model.customHeaders)
          .map((customHeader) => customHeader[1])
          .filter((customHeader) => customHeader.isEnabled)
          .map(({ key, value }) => ({ key, value })),
      };

      api
        .post(`admin/webhook`, toSubmit)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    resetModel() {
      this.model = { ...initialModel };

      customHeaderKeys.forEach((key) => {
        this.model.customHeaders[key] = { ...initialCustomHeader };
      });
    },
    model: { ...initialModel },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "name",
          required: true,
          validator: ["string"],
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
        },
        {
          type: "input",
          inputType: "text",
          label: "URL",
          model: "url",
          required: true,
          validator: ["string"],
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
        },
        {
          type: "multiselect",
          label: "Event Types",
          model: "eventTypes",
          placeholder: "Select Event Types",
          required: true,
          validator: ["array"],
          styleClasses: ["col-md-100", "md-layout-item", "md-size-100"],
          selectOptions: {
            multiple: true,
            searchable: false,
            closeOnSelect: false,
          },
          values: [],
        },
        ...customHeaderFields,
      ],
    },
  },
};
