<template>
  <md-switch
    v-model="isEnabled"
    @change="handleChange"
  />
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
  name: "MdSwitchField",
  mixins: [abstractField],
  data() {
    return {
      isEnabled: this.value || false,
    };
  },
  watch: {
    value(newValue, _oldValue) {
      if (newValue !== this.isEnabled) {
        this.isEnabled = newValue;
      }
    },
  },
  methods: {
    handleChange(value) {
      this.value = value;
    },
  },
};
</script>

<style lang="scss">
.field-md-switch {
  &.field-md-switch--single-row {
    display: flex;
    align-items: center;
  }

  & > label {
    order: 1;
  }
}
</style>
