export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "GlobalAdminProviderDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Provider List",
  modalTitle: "Add Provider",
  defaultSort: "Name",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "master/hardware/provider";
    },
    transform: function (row) {
      return {
        id: row.id,
        Name: row.name,
        Network: row.network.technology,
      };
    },
  },
};
