import df, { formatDatetime } from "@/common/date.filter";
import { isWorkerOrEquipment } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English: "The Arrival to Zone report displays the first time entering a Zone each day.",
  Arabic:
    "يعرض تقرير 'الوصول إلى سياج جيوغرافي' وقت الدخول الأول لكل من الموجودات التي دخلت السياج جيوغرافي لكل يوم",
  default: "The Arrival to Zone report displays the first time entering a Zone each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/zonearrival`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Arrival to Zone",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["param", "Date", "ID", "Name", "Resource", "Zone", "Arrival"],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "Detail2",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorkerOrEquipment(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            Zone: x.zone_name,
            Arrival: formatDatetime(x.arrival),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
