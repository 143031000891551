const isString = (x) => typeof x === "string";

export default (a, b) => {
  if ((!isString(a) || !isString(b)) && a === b) {
    return 0;
  }

  if (!isString(a)) {
    return 1;
  }

  if (!isString(b)) {
    return -1;
  }

  const comparisonResult = a.localeCompare(b);

  // Check if result is valid; localeCompare() doesn't always return exact values of -1 or 1
  const isValidResult = [-1, 0, 1].includes(comparisonResult);

  if (!isValidResult) {
    if (comparisonResult < 0) {
      return -1;
    }

    if (comparisonResult > 0) {
      return 1;
    }

    return 0;
  }

  return comparisonResult;
};
