import api from "@/common/api.service";

export default {
  actions: [
    {
      name: "Assign Asset",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "Admin Asset Assign Project",
          params: { id: event.id },
          query: { type: "Equipment" },
        });
      },
      display: function (item) {
        return !item.Project;
      },
    },
    {
      name: "Unassign",
      icon: "location_off",
      handler: function (event) {
        api.post(`admin/asset/${event.id}/unassignproject`).then(() => {
          event.Project = null;
          event.Roster = null;
        });
      },
      display: function (item) {
        return item.Project;
      },
    },
    {
      name: "Update",
      icon: "edit",
      handler: function (event, router) {
        router.push({
          name: "Update Asset",
          params: { id: event.id },
          query: { type: "Equipment" },
        });
      },
      display: function () {
        return true;
      },
    },
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "AssetDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Equipment List",
  modalTitle: "Add Equipment",
  modalUpdateTitle: "Update Equipment",
  defaultSort: "ID",
  defaultSortOrder: "asc",
  resource: {
    url: function () {
      return "admin/asset?type=Equipment";
    },
    transform: function (x) {
      return {
        id: x.id,
        ID: x.employee_id,
        Name: `${x.name}`,
        Resource: x.resource,
        Contractor: x.contractor,
        Project: x.project,
      };
    },
  },
  speedDial: [
    {
      to: "/admin/bulk/asset/register?type=Equipment",
      icon: "airport_shuttle",
      label: "Bulk Registration",
    },
    {
      to: "/admin/bulk/asset/assigndevice",
      icon: "devices_other",
      label: "Bulk Assign Device",
    },
    {
      to: "/admin/bulk/asset/assignproject",
      icon: "assignment",
      label: "Bulk Assign Project",
    },
  ],
  form: {
    title: "New Asset",
    options: {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    },
    async data(form, api) {
      if (form.id) {
        await api.get(`admin/asset/${form.id}`).then((res) => {
          const dataModel = {
            asset_name: res.data.name,
            resource: res.data.resource_id,
            contractor: res.data.contractor_id,
            hourly_rate: Number(res.data.wage),
            employee_id: res.data.employee_id,
            manager: res.data.manager_id,
            type: res.data.resource_type,
          };

          form.form.model = dataModel;
        });
      }

      api.get(`admin/resource`).then((res) => {
        form.form.schema.fields.find((x) => x.model === "resource").values = function () {
          return res.data
            .filter((x) => x.type === "Equipment")
            .map((x) => {
              return {
                id: x.id,
                name: x.name,
              };
            });
        };
      });

      api.get(`admin/contractor`).then((res) => {
        form.form.schema.fields.find((x) => x.model === "contractor").values = function () {
          return res.data.map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          });
        };
      });
    },
    submit(form, api, userId) {
      const url = form.id ? `admin/asset/${form.id}` : "admin/asset";

      this.model.manager = userId;

      api
        .post(url, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.getRows();
          form.classicModal = false;
        })
        .then(() => {
          if (form.id) {
            return form.$router.push({
              name: "Admin Assets",
              query: { type: "Equipment" },
            });
          }

          return;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    update(form, api, userId) {
      const url = form.id ? `admin/asset/${form.id}` : "admin/asset";

      this.model.manager = userId;

      api
        .put(url, this.model)
        .then(() => {
          form.$emit("submitSuccess");
          form.$notifyMethods.formSubmitSuccess();
          form.classicModal = false;
        })
        .then(() => {
          if (form.id) {
            return form.$router.push({
              name: "Admin Assets",
              query: { type: "Equipment" },
            });
          }

          return;
        })
        .catch((err) => {
          console.error(err);
          form.$notifyMethods.formSubmitFail();
        });
    },
    disableInputs(form, shouldDisable = true) {
      const inputs = ["employee_id"];
      const fieldKeys = Object.keys(form.schema.fields);

      fieldKeys.forEach((fieldKey) => {
        const field = form.schema.fields[fieldKey];

        form.schema.fields[fieldKey] = {
          ...field,
          disabled: shouldDisable && inputs.includes(field.model),
        };
      });
    },
    resetModel() {
      this.model = {
        asset_name: null,
        resource: null,
        contractor: null,
        hourly_rate: null,
        overtime_rate: null,
        employee_id: null,
        manager: null,
        type: "Equipment",
      };
      this.id = null;
    },
    closeRoute(router) {
      router.push({
        name: "Admin Assets",
        query: { type: "Equipment" },
      });
    },
    model: {
      asset_name: null,
      resource: null,
      contractor: null,
      hourly_rate: null,
      overtime_rate: null,
      employee_id: null,
      manager: null,
      type: "Equipment",
    },
    schema: {
      fields: [
        {
          type: "input",
          inputType: "text",
          label: "ID Number",
          model: "employee_id",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "input",
          inputType: "text",
          label: "Name",
          model: "asset_name",
          validator: ["required", "string"],
          required: true,
        },
        {
          type: "select",
          label: "Resource",
          model: "resource",
          selectOptions: {
            noneSelectedText: "Select Resource...",
          },
          values: [],
          required: true,
          validator: "required",
        },
        {
          type: "select",
          label: "Contractor",
          model: "contractor",
          selectOptions: {
            noneSelectedText: "Select Contractor...",
          },
          values: [],
          required: true,
          validator: "required",
        },
        {
          type: "input",
          inputType: "number",
          label: "Hourly Rate",
          model: "hourly_rate",
          validator: ["required", "number"],
          min: 0,
          required: true,
        },
      ],
    },
  },
};
