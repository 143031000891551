import api from "@/common/api.service";

export default {
  actions: [
    {
      name: "Assign Beacon",
      icon: "assignment",
      handler: function (event, router) {
        router.push({
          name: "Admin Beacon Assign",
          params: { id: event.id },
        });
      },
      display: function (item) {
        return !item.Assigned;
      },
    },
    {
      name: "Unassign",
      icon: "location_off",
      handler: function (event) {
        api.post(`admin/hardware/${event.id}/unassign`).then(() => {
          event.Assigned = null;
          event["Assignment Date"] = null;
          event.Building = null;
          event.Level = null;
          event.Resource = null;
        });
      },
      display: function (item) {
        return item.Assigned;
      },
    },
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "Admin Beacon Detail",
          params: { id: event.id },
        });
      },
      display: function () {
        return true;
      },
    },
  ],
  title: "Beacons",
  modalTitle: "Add Beacon",
  resource: {
    url: function () {
      return "admin/hardware?type=beacon";
    },
    transform: function (x) {
      return {
        id: x.id,
        Serial: x.serial,
        Assigned: x.asset || x.point,
        "Assignment Date": x.assigned ? x.assigned.substring(0, 10) : null,
      };
    },
  },
};
