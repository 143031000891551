import { defineStore } from "pinia";

export const useTenantResourceStore = defineStore("tenantResource", {
  state: () => ({
    hasLoadedResources: false,
    resources: new Map(),
  }),
  actions: {
    transformResourceData({ data }) {
      const transformedData = data.map((x) => [x.name, x]);

      return new Map(transformedData);
    },
  },
});
