export const userRoutes = [
  {
    path: "/user",
    component: () => import("@/pages/User/Layout/UserLayout.vue"),
    children: [
      {
        path: "/",
        name: "User",
        components: {
          default: () => import("@/pages/User/User.vue"),
        },
      },
    ],
  },
];
