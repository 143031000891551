import Vue from "vue";
import { io } from "socket.io-client";
import { API_URL } from "@/common/config";

const defaultOptions = {
  reconnectionAttempts: 100,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 20000,
  withCredentials: true,
};

let socketService = new Vue({
  methods: {
    async connect(options, debug = false) {
      // example of passing token or using callback
      //const token = await this.$auth.getTokenSilently();

      const socketOptions = Object.assign(
        //{ auth: { token } },
        {
          auth: async (cb) => {
            cb({
              token: await this.$auth.getTokenSilently(),
            });
          },
        },
        defaultOptions,
        options
      );

      const socket = io(API_URL, socketOptions);

      if (debug) {
        socket.on("connect_error", (err) => {
          console.error("SOCKET SERVICE CONNECT ERROR");
          console.error(err, err.type, err.description);
        });

        socket.on("connect", () => {
          console.log("SOCKET SERVICE CONNECT");
        });

        socket.on("disconnect", () => {
          console.log("SOCKET SERVICE DISCONNECT");
        });
      }

      return socket;
    },
  },
});

export default socketService;

export const SocketIOServicePlugin = {
  install: function (Vue) {
    Vue.prototype.$socketio = socketService;
  },
};
