import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Tool Utilization report displays the estimated duration of time that a Tool was being used by any Worker for each day.",
  Arabic:
    "يعرض تقرير 'استخدام الأدوات' المدة المقدرة للوقت الذي تم فيه استخدام أي عامل لأي من الأدوات لكل يوم",
  default:
    "The Tool Utilization report displays the estimated duration of time that a Tool was being used by any Worker for each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/toolutilization`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Tool Utilization",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["Date", "ID", "Name", "Resource", "Hours"],
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: "Tool" },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report.map((x) => {
        return {
          // use `param` to avoid dup key error; `id` is reserved for key
          param: x.asset_id,
          Date: df(x.date),
          ID: x.employee_id,
          Name: x.name,
          Resource: x.resource_name,
          Hours: roundNum(x.hours_in_use, 1),
        };
      });
    },
  },
};
