import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { isWorker } from "@/common/util/resourceTypes";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Overtime report shows Workers who spent more time on site than scheduled for each day.",
  Arabic: "يعرض تقرير 'العمل الإضافي' العمال الذين أمضوا وقتًا أطول في الموقع من المقرر لكل يوم",
  default:
    "The Overtime report shows Workers who spent more time on site than scheduled for each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api
      .query(`project/${filters.filters.project.id}/report/overtime`, {
        params: filters.getParams(),
      })
      .then((res) => {
        return res;
      });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Overtime",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: [
      "param",
      "Date",
      "ID",
      "Name",
      "Resource",
      "Scheduled Hours",
      "Hours on Site",
      "Overtime",
    ],
    actions: [
      {
        name: "Detail",
        icon: "map",
        handler: function (event, router) {
          router.push({
            name: "Asset Map",
            params: { id: event.param },
            query: { date: event.Date },
          });
        },
        display: function () {
          return true;
        },
      },
      {
        name: "AssetDetail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: event.resourcetype_name },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report
        .filter((x) => isWorker(x.resourcetype_name))
        .map((x) => {
          return {
            // use `param` to avoid dup key error; `id` is reserved for key
            param: x.asset_id,
            Date: df(x.date),
            ID: x.employee_id,
            Name: x.name,
            Resource: x.resource_name,
            "Hours on Site": roundNum(x.hours_on_site, 1),
            "Scheduled Hours": roundNum(x.scheduled_hours, 1),
            Overtime: roundNum(x.overtime_hours, 1),
            resourcetype_name: x.resourcetype_name,
          };
        });
    },
  },
};
