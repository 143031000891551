import { formatDatetime } from "@/common/date.filter";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  English:
    "The Workers in Zone by Hour report displays the count of Workers in each zone for each hour of each day.",
  Arabic: "يعرض التقرير عدد العمال في كل سياج جيوغرافي لكل ساعة من كل يوم",
  default:
    "The Workers in Zone by Hour report displays the count of Workers in each zone for each hour of each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/workersinzonebyhour`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: false,
    computeFn() {
      return [];
    },
  },
  table: {
    title: "Workers in Zone by Hour",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: ["Datetime", "Zone", "Total Workers"],
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function (_event, _router) {
          // Do nothing
        },
        display: function () {
          return false;
        },
      },
    ],
    updateRows(report) {
      return report.map((x) => {
        return {
          Datetime: formatDatetime(x.datetime),
          Zone: x.zone_name,
          "Total Workers": x.worker_count,
        };
      });
    },
  },
};
