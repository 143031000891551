export default {
  actions: [
    {
      name: "Detail",
      icon: "info",
      handler: function (event, router) {
        router.push({
          name: "GlobalAdminModelDetail",
          params: { id: event.id },
        });
      },
      display: function () {
        return false;
      },
    },
  ],
  title: "Model List",
  modalTitle: "Add Model",
  resource: {
    url: function () {
      return "master/hardware/model";
    },
    transform: function (row) {
      return {
        id: row.id,
        Name: row.name,
        Manufacturer: row.manufacturer,
      };
    },
  },
};
