import df from "@/common/date.filter";
import roundNum from "@/common/util/roundNum";
import { tooltipLanguage } from "@/common/config";

const tooltips = {
  Arabic: "يعرض تقرير 'استخدام المعدات' مدة  استخدام المعدات كل يوم",
  English:
    "The Equipment Utilization report displays the duration of time Equipment was active each day.",
  default:
    "The Equipment Utilization report displays the duration of time Equipment was active each day.",
};

export default {
  fetchData(api, filters, _route) {
    return api.query(`project/${filters.filters.project.id}/report/utilization`, {
      params: filters.getParams(),
    });
  },
  stats: {
    isStatsAvailable: true,
    computeFn(report) {
      return [
        {
          header: "Total Hours",
          val:
            report.length > 0
              ? report.map((x) => (x.hours_in_use ? x.hours_in_use : 0)).reduce((a, b) => a + b, 0)
              : "NA",
          suffix: "hrs",
          link: "",
          decimals: 1,
        },
        {
          header: "Total Days",
          val: report.length > 0 ? report.length : "NA",
          suffix: "days",
          link: "",
          decimals: 0,
        },
        {
          header: "Avg Speed",
          val:
            report.length > 0
              ? report
                  .map((x) => (x.avg_speed_kph ? x.avg_speed_kph : 0))
                  .reduce((a, b) => a + b, 0) / report.length
              : "NA",
          suffix: "kph",
          link: "",
          decimals: 0,
        },
        {
          header: "Avg Distance",
          val:
            report.length > 0
              ? report.map((x) => (x.distance_km ? x.distance_km : 0)).reduce((a, b) => a + b, 0) /
                report.length
              : "NA",
          suffix: "km",
          link: "",
          decimals: 0,
        },
      ];
    },
  },
  table: {
    title: "Equipment Utilization",
    tooltip: tooltips[tooltipLanguage] ? tooltips[tooltipLanguage] : tooltips.default,
    headers: [
      "ID",
      "Name",
      "Resource",
      "Date",
      "Hours Active",
      "Avg Speed (km/h)",
      "Distance (km)",
    ],
    actions: [
      {
        name: "Detail",
        icon: "info",
        handler: function (event, router) {
          router.push({
            name: "ProjectAssetDetail",
            params: { id: event.param },
            query: { type: "Equipment" },
          });
        },
        display: function () {
          return true;
        },
      },
    ],
    updateRows(report) {
      return report.map((x) => {
        return {
          // use `param` to avoid dup key error; `id` is reserved for key
          param: x.id,
          Date: df(x.date),
          ID: x.employee_id,
          Name: x.name,
          Resource: x.resource_name,
          "Hours Active": roundNum(x.hours_in_use, 1),
          "Avg Speed (km/h)": roundNum(x.avg_speed_kph, 1),
          "Distance (km)": roundNum(x.distance_km, 1),
        };
      });
    },
  },
};
