import { isDev, isTesting, isDevOrTesting } from "./util/env";

const env = process.env.VUE_APP_ENV || "production";

export const brand = "corevos";

// eslint-disable-next-line no-unused-vars
const OFF = false;
// eslint-disable-next-line no-unused-vars
const ON = true;

export const FEATURES = {
  contactTracing: OFF,
  cost: OFF,
  deleteLocations: ON,
  deletePoints: ON,
  dataFlags: OFF,
  help: OFF,
  markerPopupNearbyAssets: ON,
  phoneNumbers: ON,
  productivity: ON,
  scanners: OFF,
  sendDeviceAlert: ON,
  fota: ON,
  nfcTags: ON,
  eventNotificationApi: OFF,
  devControls: isDevOrTesting(env),
  inProgress: OFF, // show/hide features in progress
};

export const GOOGLE_MAPS_OPTIONS = {
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  version: "quarterly",
};

export const AUTH_METHOD = "local";

export const AUTH_CONFIG = isDevOrTesting(env)
  ? {
      clientId: "YUAZ0JeFcwH4OZp2IDv2Ic3Cjl1Kpzt2",
      domain: "ottogeeinc.auth0.com",
      apiUrl: "https://ottogeeinc.auth0.com/api/v2/",
      audience: "https://ottogeeinc.auth0.com/api/v2/",
      logoutUrl: "http://localhost:8080/",
      namespace: "https://ottogee.com",
    }
  : {
      clientId: "YUAZ0JeFcwH4OZp2IDv2Ic3Cjl1Kpzt2",
      domain: "ottogeeinc.auth0.com",
      apiUrl: "https://ottogeeinc.auth0.com/api/v2/",
      audience: "https://ottogeeinc.auth0.com/api/v2/",
      logoutUrl: "https://core.ottogee.com/",
      namespace: "https://ottogee.com",
    };

export const brands = {
  ottogee: {
    name: "Ottogee",
    title: "Connected Resources",
    logo: {
      login: "/img/logo-transparent.png",
      sidebar: "/img/ottogee-logo3.svg",
    },
    url: "https://www.ottogee.com",
    login: {
      title: "Connected Resources",
      subtitle: "CORE",
      headerColor: "blue",
    },
    providers: ["ottogee", "auth0"],
    apiUrl: "https://core-api.ottogee.com/",
    csvFilename: "OttogeeData.csv",
  },
  corevos: {
    name: "CoRe",
    title: "Connected Resources",
    logo: {
      login: "/img/corevos-logo-wide-no-tagline.png",
      sidebar: "/img/corevos-logo-circle.png",
    },
    url: "https://app.corevos.com",
    login: {
      title: "Connected Resources",
      subtitle: "CORE",
      headerColor: "blue",
    },
    providers: ["ottogee"],
    apiUrl: "https://app-api.corevos.com/",
    csvFilename: "CoReData.csv",
  },
  etisalat: {
    name: "Etisalat Digital",
    title: "Connected Worker",
    logo: {
      login: "/img/etisalat-digital.png",
      sidebar: "/img/et-digital-circle-logo.png",
    },
    url: "https://www.etisalatdigital.ae",
    login: {
      title: "Connected Worker",
      subtitle: "CORE",
      headerColor: "blue",
    },
    providers: ["ottogee", "auth0"],
    apiUrl: "https://connectedworker-api.etisalatdigital.ae/",
    csvFilename: "EtisalatDigitalData.csv",
  },
  iot2: {
    name: "iot2 CW",
    title: "Connected Workforce",
    logo: {
      login: "/img/iot2-logo-light.svg",
      sidebar: "/img/iot2-logo.svg",
    },
    url: "https://cw-app.iotsquared.com.sa",
    login: {
      title: "Connected Workforce",
      subtitle: "CW",
      headerColor: "purple",
    },
    providers: ["ottogee"],
    apiUrl: "https://cw-api.iotsquared.com.sa/",
    csvFilename: "iot2-cw-data.csv",
  },
};

export const getApiUrl = (environment) => {
  let apiUrl;

  if (isDev(environment)) {
    apiUrl = "http://localhost:3201/";
  } else if (isTesting(environment)) {
    apiUrl = "http://localhost:1337/";
  } else {
    apiUrl = brands[brand].apiUrl;
  }

  return apiUrl;
};

export const API_URL = getApiUrl(env);

export const BRAND_CONFIG = brands[brand];

export const tooltipLanguage = "English"; //"English","Arabic" - defaults to english

export default API_URL;
