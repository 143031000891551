import { isValid, parseISO } from "date-fns";

export const padTime = (val) => val.toString().padStart(2, "0");

export default (str) => {
  if (!str) {
    return null;
  }

  return str.substr(0, 10).replace("T", " ");
};

export const formatDatetime = (str) => {
  if (!str) {
    return null;
  }

  return str.substr(0, 16).replace("T", " ");
};

export const formatDuration = (duration, isHours = false) => {
  const isValidDuration = typeof duration === "number" && !isNaN(duration) && duration > 0;

  if (!isValidDuration) {
    return "00:00";
  }

  const hours = isHours ? duration : duration / 60;
  const hoursWhole = Math.floor(hours);
  const minutes = isHours ? duration * 60 : duration;
  const minutesWhole = Math.floor(minutes) % 60;

  return `${padTime(hoursWhole)}:${padTime(minutesWhole)}`;
};

export const getDateFromISO = (isoString, removeZeroUTCOffset = true) => {
  const isValidISOString = typeof isoString === "string" && isValid(new Date(isoString));
  const isValidRemoveZeroUTCOffset = typeof removeZeroUTCOffset === "boolean";

  if (!isValidISOString || !isValidRemoveZeroUTCOffset) {
    return null;
  }

  const isoStringFormatted = removeZeroUTCOffset ? isoString.replace(/Z$/, "") : isoString;
  const date = parseISO(isoStringFormatted);

  return date;
};

export const getDateString = (date, returnTime = false) => {
  const isValidDate = date !== null && typeof date !== "boolean" && isValid(new Date(date));

  if (!isValidDate) {
    return "";
  }

  const isString = typeof date === "string";
  const dateInstance = isString ? parseISO(date) : new Date(date);
  const dateWithoutOffset = new Date(
    dateInstance.getTime() - dateInstance.getTimezoneOffset() * 60000
  );
  const dateWithoutOffsetSubstrings = dateWithoutOffset.toISOString().split("T");
  const dateString = returnTime ? dateWithoutOffsetSubstrings[1] : dateWithoutOffsetSubstrings[0];

  return dateString;
};

export const getDuration = (obj, unit = "", labelTime = true) => {
  if (!obj) {
    return null;
  }

  let { days, hours, minutes, seconds } = obj;

  if (!unit) {
    return labelTime
      ? // 00h 00m 00s
        `${padTime(hours || "00")}h ${padTime(minutes || "00")}m ${padTime(seconds || "00")}s`
      : // 00:00:00
        `${padTime(hours || "00")}:${padTime(minutes || "00")}:${padTime(seconds || "00")}`;
  }

  // Note: undefined or null = 0 for math
  days = days || 0;
  hours = hours || 0;
  minutes = minutes || 0;
  seconds = seconds || 0;

  if (unit === "days" || unit === "d") {
    return days + hours / 24 + minutes / 1440;
  }

  if (unit === "hours" || unit === "hrs") {
    return days * 24 + hours + minutes / 60;
  }

  if (unit === "minutes" || unit === "mins") {
    return days * 1440 + hours * 60 + minutes;
  }

  if (unit === "seconds" || unit === "s") {
    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
  }

  throw new Error("invalid unit");
};
